<template>
  <div class="ml-3 mr-6">
    <v-card
      class="bg-primary"
      rounded="xl"
      elevation="4"
      height="230px"
    >
      <v-card-title class="ml-4 mt-4">Compensation Cycle</v-card-title>
    </v-card>

    <v-card
      rounded="xl"
      elevation="4"
      class="mt-4 mb-4 pa-6"
    >
      <v-card-text>
        <v-row>
          <v-col><div class="d-flex justify-end"><v-switch
            variant="solo"
            v-model="CompensationCycle.IsActive"
            label="Active?"
            color="success"
            hide-details
            readonly
          >
          </v-switch></div></v-col>
        </v-row>
        <v-row>
          <v-col><v-select
            variant="solo"
            density="compact"
            label="Period"
            v-model="selectedPeriod"
            :items="PERFORMANCECYCLEPERIODS"
            :readonly="!CompensationCycle.IsActive"
          ></v-select></v-col>
          <v-col><v-text-field
            variant="solo"
            density="compact"
            v-model="CompensationCycle.Year"
            :rules="numericalRules"
            label="Year"
            type="number"
            :readonly="!CompensationCycle.IsActive"
          ></v-text-field></v-col>
          <v-col></v-col>
          <v-col>
              <datepicker
                density="compact"
                color="secondary"
                variant="solo"
                label="Start Date"
                v-model="CompensationCycle.CompensationStartDate"
                :readonly="!CompensationCycle.IsActive"
                ></datepicker>
          </v-col>
          <v-col>
              <datepicker
                density="compact"
                color="secondary"
                variant="solo"
                label="End Date"
                v-model="CompensationCycle.CompensationEndDate"
                :readonly="!CompensationCycle.IsActive"
                ></datepicker>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              density="compact"
              variant="solo"
              :items="CompensationTypes"
              :item-value="item => item.CompensationTypeID"
              :item-title="item => item.CompensationTypeName"
              label="Compensation Type"
              v-model="selectedCompensationType"
              :readonly="!CompensationCycle.IsActive"
            >
            </v-select>
          </v-col>
          <v-col>
            <div class="text-caption text-grey">Length of Service (Months)</div>
            <v-slider
              density="compact"
              variant="solo"
              color="secondary"
              class="mt-n3"
              v-model="CompensationCycle.LengthOfServiceThreshold"
              :min="0"
              :max="12"
              :step="1"
              thumb-label
              type="number"
              :readonly="!CompensationCycle.IsActive"
            >
              <template v-slot:append>
                <v-text-field
                  density="compact"
                  variant="solo"
                  class="mt-n2"
                  v-model="CompensationCycle.LengthOfServiceThreshold"
                  type="number"
                  style="width: 80px"
                  hide-details
                  :readonly="!CompensationCycle.IsActive"
                />
              </template>
            </v-slider>
          </v-col>
          <v-col>
            <div class="text-caption text-grey">Average Review Calculation (%)</div>
            <v-slider
              density="compact"
              variant="solo"
              color="secondary"
              class="mt-n3"
              v-model="CompensationCycle.CalculationThreshold"
              :min="0"
              :max="100"
              :step="1"
              thumb-label
              type="number"
              :readonly="!CompensationCycle.IsActive"
            >
          <template v-slot:append>
            <v-text-field
              density="compact"
              variant="solo"
              class="mt-n2"
              v-model="CompensationCycle.CalculationThreshold"
              type="number"
              style="width: 80px"
              hide-details
              :readonly="!CompensationCycle.IsActive"
          />
          </template>
        </v-slider></v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row v-if="CompensationCycle.CompensationCycleID != 'new'">
          <v-col>
            <v-select
              variant="solo"
              cols="3"
              label="Performance Cycles"
              multiple
              chips
              v-model="selectedPerformanceCycles"
              :items="PerformanceCycles"
              :item-value="item => item.PerformanceCycleID"
              :item-title="item => `${item.Year} - ${getPerformanceCyclePeriod(item.Period)}`"
              :readonly="!CompensationCycle.IsActive"
            >
            </v-select>
  
            <div class="d-flex justify-center" v-if="selectedCompensationType == 0">
              <v-data-table
                density="compact"
                class="w-50"
                :loading="loadingInflationRates"
                :headers="CountryInflationRateHeaders"
                :items="CountryInflationRates"
                items-per-page="-1"
                hide-default-footer="true"
                no-data-text="No Inflation Rates, please save the Compensation Cycle first">
                <template v-slot:loading>
                  <v-skeleton-loader type="table-row@5"></v-skeleton-loader>
                </template>
                <template v-slot:item.CountryName="{ item }">
                  {{ item.CountryName }} ({{ item.CurrencyCode }})
                </template>
                <template v-slot:item.actions="{ item }">
                  <div>
                    <v-btn
                      color="primary"
                      icon=""
                      variant="plain"
                      density="compact"
                      :disabled="!CompensationCycle.IsActive"
                      @click="editCountryInflationRate(item)"
                    ><v-icon size="sm" icon="mdi-pencil" />
                      <v-tooltip
                        activator="parent"
                        location="top"
                      >Edit {{ item.CountryName }} ({{ item.CurrencyCode }})
                      </v-tooltip>
                    </v-btn>
                  </div>
                </template>
                <template v-slot:item.Rounding="{ item }">
                  {{ getLookupRoundingName(item.Rounding) }}
                </template>
                <template v-slot:bottom> </template>
              </v-data-table>
  
              <v-dialog v-model="dialogEditCountryInflation" max-width="750px">
                <v-card>
                  <v-card-title>
                    Edit {{ editedCountryInflationRate.CountryName }} Inflation Rate
                  </v-card-title>
                  <v-card-subtitle>
                    Set the Annual Base Inflation Rate and US$ Foreign Exchange Rate to be used for the calculation of increases
                  </v-card-subtitle>
                  <v-card-text>
                    <v-form v-model="isEditCountryInflationRateValid">
                      <v-text-field
                        v-model="editedCountryInflationRate.BaseInflationRate"
                        variant="solo"
                        :rules="numericalRules"
                        label="Base Inflation Rate (%)"
                        hint="The Average Inflation Percentage within the Country"
                        type="number"
                      />
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="editedCountryInflationRate.BaseUSDForexRate"
                        variant="solo"
                        :rules="numericalRules"
                        label="Base Forex Rate (USD)"
                        hint="The Foreign Exchange rate versus USD (1USD = 1XXX)"
                        type="number"
                      />
                      <v-select
                        variant="solo"
                        density="compact"
                        label="Rounding"
                        v-model="editedCountryInflationRate.Rounding"
                        :rules="numericalRules"
                        :items="lookupRounding"
                      ></v-select>
                    </v-form>
                  </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="success" :disabled="!isEditCountryInflationRateValid" variant="text" @click="saveRate">Save</v-btn>
                      <v-btn color="error" variant="text" @click="closeDialogEditCountryInflation">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="ma-1"
          variant="tonal"
          @click="
            router.push({
              name: 'compensation-cycle',
            })"
        >Back</v-btn>
                
        <v-btn
          v-if="CompensationCycle.IsActive"
          class="ma-1"
          variant="tonal"
          @click="saveAll()"
        > {{ CompensationCycle.CompensationCycleID === "new" ? "Create" : "Save" }}</v-btn>
  
        <v-dialog v-model="dialogCalculate" :persistent="true" width="auto" v-if="CompensationCycle.CompensationCycleID != 'new' && CompensationCycle.IsActive">
          <template v-slot:activator="{ props }">
            <v-btn color="primary" v-bind="props" class="ma-1" variant="tonal"
            >Calculate
          </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">Calculate Compensation Cycle</v-card-title>
            <v-card-text>
              Are you sure you want to calculate the Compensation Cycle?
              <v-spacer></v-spacer>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" variant="text" @click="calculateCompensationCycle()">Yes</v-btn>
              <v-btn color="error" variant="text" @click="closeDialogCalculate()">No</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  
        <v-dialog v-model="dialogClose" :persistent="true" width="auto" v-if="CompensationCycle.CompensationCycleID != 'new' && CompensationCycle.IsActive">
          <template v-slot:activator="{ props }">
            <v-btn color="primary" v-bind="props" class="ma-1" variant="tonal">Close</v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">Close Compensation Cycle</v-card-title>
            <v-card-text>
                Are you sure you want to close this Compensation Cycle #{{ CompensationCycle.CompensationCycleID }}?
                <v-spacer></v-spacer>
                By confirming this dialog you are confirming that all open Compensation Reviews are complete and finalized.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" variant="text" @click="closeCompensationCycle()">Yes</v-btn>
              <v-btn color="error" variant="text" @click="closeDialogClose">No</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="ma-1"
          variant="tonal"
          @click="routeToCompensationList()">Compensation List</v-btn>
      </v-card-actions>
    </v-card>
    <progressdialog v-model="completeInProgress" :message="inProgressMessage"></progressdialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import authService from "@/services/authService"
import utils from "@/services/utils"

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const route = useRoute()
const router = useRouter()

const compensationCycleID = route.params.id
var CompensationTypes = ref([])
var PerformanceCycles = ref([])
var CompensationCycle = ref({})
const lookupRounding = ref([
  { title: "1 000", value: 1000 },
  { title: "10 000", value: 10000 },
  { title: "100 000", value: 100000 },
  { title: "1 000 000", value: 1000000 },
])

const CountryInflationRateHeaders = [
  { title: "Country", key: "CountryName" },
  { title: "Base Inflation Rate (%)", key: "BaseInflationRate", value: "BaseInflationRate", align: "end" },
  { title: "Base Forex Rate (USD)", key: "BaseUSDForexRate", value: "BaseUSDForexRate", align: "end" },
  { title: "Rounding", key: "Rounding", value: "Rounding", align: "end" },
  { title: "Actions", key: "actions", width: "90px", sortable: false },
]
const CountryInflationRates = ref([])
var editedCountryInflationRateIndex = ref(null)
var editedCountryInflationRate = ref([])
const PERFORMANCECYCLEPERIODS = ref(utils.PERFORMANCECYCLEPERIODS)

const selectedPeriod = ref(null)
const selectedCompensationType = ref(null)
const selectedPerformanceCycles = ref(null)
const loadingInflationRates = ref(true)
const isEditCountryInflationRateValid = ref(false)
const dialogCalculate = ref(false)
const dialogClose = ref(false)
const dialogEditCountryInflation = ref(false)
const completeInProgress = ref(false)
const inProgressMessage = ref("")

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

function routeToCompensationList(itemID) {
  router.push({
    name: "compensation-cycle-compensation",
    params: { id: itemID },
  })
}

// Validation rules
const numericalRules = [
  (v) => !!v || "Numerical value is required",
  (v) => (!isNaN(parseFloat(v)) && isFinite(v)) || "must be a number",
]

function getLookupRoundingName(v) {
  const rtn = lookupRounding.value.filter(item => item.value === v)[0].title
  return rtn ? rtn : 1000
}

async function getPerformanceCycles() {
  await utils.httpRequest(`/PerformanceCycles`, utils.REQUEST.GET, (data) => {
      PerformanceCycles.value = data
    }
  )
}

async function getCompensationTypes() {
  await utils.httpRequest(`/Static/CompensationTypes`, utils.REQUEST.GET, (data) => {
      CompensationTypes.value = data.filter(item => item.CompensationTypeID >= 0)
    }
  )
}

async function getCompensationCycle() {
  await utils.httpRequest(`/CompensationCycles/${compensationCycleID}`, utils.REQUEST.GET, (data) => {
      CompensationCycle.value = data
      selectedPeriod.value = CompensationCycle.value.Period
      selectedCompensationType.value = CompensationCycle.value.CompensationTypeID
    }
  )

  await utils.httpRequest(`/CompensationCycles/${compensationCycleID}/PerformanceCycles`, utils.REQUEST.GET, (data) => {
      selectedPerformanceCycles.value = data.map(item => item.PerformanceCycleID).reverse()
    }
  )

  await utils.httpRequest(`/CompensationCycles/${compensationCycleID}/CountryInflationRates`, utils.REQUEST.GET, (data) => {
      loadingInflationRates.value = true
      CountryInflationRates.value = data
      loadingInflationRates.value = false
    }
  )
}

async function saveAll() {
  await saveCompensationCycle()
  if (compensationCycleID != "new") {
    await saveCountryInflationRate()
    await savePerformanceCycles()
  }
}

async function saveCountryInflationRate() {
  try {
    let payload = CountryInflationRates.value.map(item => ({ CountryID: item.CountryID, BaseInflationRate: item.BaseInflationRate, BaseUSDForexRate: item.BaseUSDForexRate, Rounding: item.Rounding }))
    await utils.httpRequest(`/CompensationCycles/${compensationCycleID}/CountryInflationRates`, utils.REQUEST.POST, (data) => {
        if (data.Error) {
          throw new Error(data.Error.Message)
        }
      },
      payload
    )
    return
  } catch (e) {
    updateResultMessage("error|Data Issue|" + e.message)
  }
}

async function savePerformanceCycles() {
  try {
    let payload = selectedPerformanceCycles.value.map(item => ({ PerformanceCycleID: item }))
    await utils.httpRequest(`/CompensationCycles/${compensationCycleID}/PerformanceCycles`, utils.REQUEST.POST, (data) => {
        if (data.Error) {
          throw new Error(data.Error.Message)
        }
      },
      payload
    )
    return
  } catch (e) {
    updateResultMessage("error|Data Issue|" + e.message)
  }
}

async function saveCompensationCycle() {
  try {
    CompensationCycle.value.Period = selectedPeriod.value
    CompensationCycle.value.CompensationTypeID = selectedCompensationType.value
    let payload = CompensationCycle.value
    let uri = `/CompensationCycles/`
    if (compensationCycleID != "new") {
      uri = uri + compensationCycleID
    }
    await utils.httpRequest(uri, utils.REQUEST.POST, (data) => {
        if (!data.Error) {
          router.push({
            name: "compensation-cycle-form",
            params: { id: data.CompensationCycleID },
          })
          updateResultMessage("success|Done|Successfully Saved Compensation Cycle")
        } else {
          throw new Error(data.Error.Message)
        }
      },
      payload
    )
    return
  } catch (e) {
    updateResultMessage("error|Data Issue|" + e.message)
  }
}

async function calculateCompensationCycle() {
  inProgressMessage.value="Calculating Compensation for the Cycle, please hold..."
  completeInProgress.value = true

  try {
    await utils.httpRequest(`/CompensationCycles/${compensationCycleID}/Calculate`, utils.REQUEST.POST, (data) => {
        if (!data.Error) {
          updateResultMessage("success|Success|Compensation Cycle #" + compensationCycleID + " calculated successfully")
          getCompensationCycle()
        } else {
          throw new Error(data.Error.Message)
        }
      }
    )
  } catch (e) {
    updateResultMessage("error|Data Issue|" + e.message)
  }
  completeInProgress.value = false
  dialogCalculate.value = false
}

function editCountryInflationRate (item) {
  editedCountryInflationRateIndex.value = CountryInflationRates.value.indexOf(item)
  editedCountryInflationRate.value = Object.assign({}, item)
  dialogEditCountryInflation.value = true
}

async function closeCompensationCycle() {
  inProgressMessage.value="Closing Compensation Cycle, please hold..."
  completeInProgress.value = true

  try {
    await utils.httpRequest(`/CompensationCycles/${compensationCycleID}/Close`, utils.REQUEST.POST, (data) => {
        if (!data.Error) {
          updateResultMessage("success|Success|Compensation Cycle #" + compensationCycleID + " closed successfully")
          getCompensationCycle()
        } else {
          throw new Error(data.Error.Message)
        }
      }
    )
    dialogClose.value = false
  } catch (e) {
    updateResultMessage("error|Data Issue|" + e.message)
  }
  completeInProgress.value = false
}

function saveRate () {
  if (editedCountryInflationRateIndex.value > -1) {
    Object.assign(CountryInflationRates.value[editedCountryInflationRateIndex.value], editedCountryInflationRate.value)
  } else {
    CountryInflationRates.value.push(editedCountryInflationRate.value)
  }
  closeDialogEditCountryInflation()
}

function closeDialogCalculate () {
  dialogCalculate.value = false
}

function closeDialogClose () {
  dialogClose.value = false
}

function closeDialogEditCountryInflation () {
  dialogEditCountryInflation.value = false
}

function getPerformanceCyclePeriod(key) {
  try {
    return utils.PERFORMANCECYCLEPERIODS[key-1].title
  }
  catch {
    return ""
  }
}

onMounted(async () => {
  emit("updateBreadcrumb", ["Administration", "Compensation Cycle", compensationCycleID])
  emit("updateHeader", "Compensation Cycle")
  if (await authService.isCompensationAdmin()) {
    getPerformanceCycles()
    getCompensationTypes()
    if (compensationCycleID != "new") {
      getCompensationCycle()
    }
    else {
      CompensationCycle.value.IsActive = true
      CompensationCycle.value.LengthOfServiceThreshold = 6
      CompensationCycle.value.CalculationThreshold = 60
      CompensationCycle.value.Year = new Date().getFullYear()
    }
  }
  else {
    updateResultMessage("error|No Access|You don't have admin role that allows you to access the page")
    router.push({
      name: "performance-review-list",
      params: {
          type: "current",
          result: "You don't have admin role that allows you to access the page",
      }
    })
  }
})
</script>
