<template>
  <v-overlay
    :model-value="model"
    class="align-center justify-center"
  >
    <v-card class="pa-2">
      <v-progress-circular
        color="secondary"
        indeterminate
        size="64"
      ></v-progress-circular>
      {{ message }}
      </v-card>
  </v-overlay>
</template>
<script setup>

const model = defineModel()

const props = defineProps({
  message: String
})

</script>
