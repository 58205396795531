<template>
  <div class="ml-3 mr-6">
    <v-expansion-panels
      rounded="xl">
      <v-expansion-panel
        static
        color="secondary">
        <v-expansion-panel-title>
          <v-row>
            <v-col class="d-flex justify-end">
              Filters and Options
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text
          class="mb-n4">
          <v-row>
            <v-col class="d-flex justify-end">
              <xlsxexportbutton class="mr-2" :rawJSON="filteredItems" :updateMessage="updateResultMessage"></xlsxexportbutton>
              <graphdialog graphType="performancereviewlinegraph" :data="filteredItems" v-if="route.params.type != 'current'"></graphdialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="7">
              <div class="mb-6 pb-10"></div>
            </v-col>
            <v-col cols="2" class="d-flex justify-end">
              <v-select
                v-if="route.params.type === 'history'"
                v-model="selectedCycle"
                clearable
                label="Performance Cycle"
                density="compact"
                :items="performanceCycles"
                :item-title="item => `${item.Year} - ${getPerformanceCyclePeriod(item.Period)}`"
                :item-value="item => item.PerformanceCycleID"
                >
              </v-select>
            </v-col>
            <v-col justify="end">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                density="compact"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      density="compact"
      :loading="loading"
      :headers="headers"
      :items="filteredItems"
      :items-per-page="route.params.type == 'history' ? -1 : 25">
      <template v-slot:loading>
        <v-skeleton-loader type="table-row@5"></v-skeleton-loader>
      </template>
      <template v-slot:item.Name="{ item }">
        <v-btn
         class="mt-n1"
         size="small"
         color="secondary"
         icon=""
         variant="text"
         density="compact"
         @click="sendMail(item.EmailAddress)"
       ><v-icon icon="mdi-email" />
        <v-tooltip
          activator="parent"
          location="top"
        >Send mail to {{ item.EmailAddress }}
        </v-tooltip></v-btn>
        {{ item.Name }}
      </template>
      <template v-slot:item.ManagerName="{ item }">
        <v-btn
         v-if="!!item.ManagerName"
         class="mt-n1"
         size="small"
         color="secondary"
         icon=""
         variant="text"
         density="compact"
         @click="sendMail(item.ManagerEmailAddress)"
       ><v-icon icon="mdi-email" />
        <v-tooltip
          activator="parent"
          location="top"
        >Send mail to {{ item.ManagerEmailAddress }}
        </v-tooltip></v-btn>
        {{ item.ManagerName }}
      </template>
      <template v-slot:item.Period="{ item }">
        {{ formatDatePeriod(item.ManagerReviewStartDate) }} - {{ formatDatePeriod(item.ManagerReviewEndDate) }}
      </template>
      <template v-slot:item.PerformancePhaseID="{ item }">
        {{ item.PerformancePhaseName }}
      </template>
      <template v-slot:item.TotalScore="{ item }">
        <v-rating
          v-if="item.TotalScore > 0"
          :model-value="item.TotalScore / (100 / 5)"
          length="5"
          half-increments
          hover
          color="secondary"
          density="compact"
          size="small"
          readonly
        >
        </v-rating>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          color="primary"
          icon=""
          variant="plain"
          density="compact"
          @click="routeToPerformanceReview(item)"
        > <v-icon size="small" icon="mdi-eye" />
          <v-tooltip
            activator="parent"
            location="top"
          >View Performance Review
          </v-tooltip>
        </v-btn>
        <v-btn
          v-if="item.PerformancePhaseID === 3"
          color="primary"
          icon=""
          variant="plain"
          density="compact"
          @click="getPerformanceReviewPDF(item)"
          > <v-icon size="small" icon="mdi-file-pdf-box" />
          <v-tooltip
            activator="parent"
            location="top"
          >Download Performance Review as PDF
          </v-tooltip>
        </v-btn>
      </template>
    </v-data-table>
  </div>
  <progressdialog v-model="downloading" message="Fetching Performance Review, please hold..."></progressdialog>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import authService from "@/services/authService"
import utils from "@/services/utils"

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const route = useRoute()
const router = useRouter()

const search = ref("")
const headers = [
  { title: "Name", key: "Name" },
  { title: "Manager", key: "ManagerName" },
  { title: "Period", key: "Period" },
  { title: "Phase", key: "PerformancePhaseID" },
  { title: "Score", key: "TotalScore", width: "150px" },
  { title: "Actions", key: "actions", width: "90px", sortable: false },
]
const userID = ref(localStorage.getItem('user_id'))
const performanceReviews = ref([])
const filteredItems = ref([])
const performanceCycles = ref([])
const selectedCycle = ref(null)
const loading = ref(true)
const downloading = ref(false)

const dialogReport = ref(false)

watch(route.params, (newParams) => {
  getData()
}, { immediate: true })

watch(selectedCycle, (newParams) => {
  resetFilter()
}, { immediate: true })

watch(search, (newParams) => {
  resetFilter()
}, { immediate: true })

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

function sendMail(emailAddress) {
  window.open(`mailto:${emailAddress}`)
  updateResultMessage("success|Sending Mail|" + emailAddress)
}

function closeDialogReport() {
  dialogReport.value = false
}

function routeToPerformanceReview(item) {
  let routeData
  if (item.PerformanceReviewID === -1) {
    routeData = router.resolve({
      name: "performance-review-metric",
      params: { id: "new" },
      query: { userid: item.UserID, performancecycleid: item.PerformanceCycleID },
    })
  } else {
    if (item.PerformancePhaseID <= 1 && (item.UserID != localStorage.getItem("user_id") || item.IsImpersonation === true)) {
      routeData = router.resolve({
        name: "performance-review-metric",
        params: { id: item.PerformanceReviewID },
      })
    } else {
      routeData = router.resolve({
        name: "performance-review-detail",
        params: { id: item.PerformanceReviewID },
      })
    }
  }

  window.open(routeData.href, "_blank")
}

async function getPerformanceReviewPDF(item) {
  downloading.value = true
  await utils.httpRequest(`/PerformanceReviews/${item.PerformanceReviewID}/document`, utils.REQUEST.GET, (response) => {
      const url = window.URL.createObjectURL(new Blob([response.blob]))
      const filename = response.filename
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      updateResultMessage("success|Download File|Downloaded Performance Review for " + item.Name)
  })
  downloading.value = false
}

function resetFilter() {
  filteredItems.value = performanceReviews.value.filter(item => {
    let isCycleMatch = selectedCycle.value ? item.PerformanceCycleID === selectedCycle.value : true
    let textSearch = utils.jsonObjectHasText(item, search.value)
    return isCycleMatch && textSearch
  })
}

function getPerformanceCyclePeriod(key) {
  try {
    return utils.PERFORMANCECYCLEPERIODS[key-1].title
  }
  catch {
    return ""
  }
}

function formatDatePeriod(dateString) {
  return utils.formatDatePeriod(dateString)
}

async function getData() {
  await getPerformanceCycles()
  await getPerformanceReviews()
}

async function getPerformanceReviews() {
  let uri = `/PerformanceReviews/User/${localStorage.getItem("user_id")}`
  switch (route.params.type) {
    case "mine":
      emit("updateBreadcrumb", ["Performance Review", "My Reviews"])
      break
    case "current":
      emit("updateBreadcrumb", ["Performance Review", "Staff Reviews"])
      uri = uri + "/current"
      break
    case "history":
      emit("updateBreadcrumb", ["Performance Review", "Historic Reviews"])
      uri = uri + "/history"
      break
  }

  await utils.httpRequest(uri, utils.REQUEST.GET, (data) => {
      performanceReviews.value = data
      loading.value = false
    }
  )
  resetFilter() //Always reset date period filter
}

async function getPerformanceCycles() {
  await utils.httpRequest(`/PerformanceCycles`, utils.REQUEST.GET, (data) => {
      performanceCycles.value = data.sort()
    }
  )
}

onMounted(async () => {
  emit("updateBreadcrumb", ["Performance Review"])
  emit("updateHeader", "Performance Review")
})
</script>
