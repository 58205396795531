<template>
  <div class="ml-3 mr-6">
    <v-expansion-panels
      rounded="xl">
      <v-expansion-panel
        static
        color="secondary">
        <v-expansion-panel-title>
          <v-row>
            <v-col class="d-flex justify-end">
              Filters and Options
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text
          class="mb-n4">
          <v-row>
            <v-col class="d-flex justify-end">
              <xlsxexportbutton class="mr-2" :rawJSON="performanceCycles" :updateMessage="updateResultMessage"></xlsxexportbutton>
              <v-btn
                variant="tonal"
                color="primary"
                icon=""
          @click="routeToPerformanceCycle(`new`)"
          >
                <v-icon icon="mdi-plus-thick" />
                <v-tooltip
                  activator="parent"
                  location="top"
                >Add New Cycle
                </v-tooltip>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col></v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      density="compact"
      v-if="performanceCycles.length != 0"
      :headers="headers"
      :items="performanceCycles"
      items-per-page="-1"
      hide-default-footer="true">
      <template v-slot:item.PerformanceCyclePeriod="{ item }">
        <v-icon
          v-if="item.IsActive"
          size="small"
          icon="mdi-check"
          color="success"
        ></v-icon>
        <v-icon
          v-if="! item.IsActive"
          size="small"
          icon="mdi-cancel"
          color="error"
        ></v-icon>
        {{ item.Year }} - {{ getPerformanceCyclePeriod(item.Period) }}
      </template>
      <template v-slot:item.ManagerReviewStartDate="{ item }">
        {{ formatDatePeriod(item.ManagerReviewStartDate) }}
      </template>
      <template v-slot:item.ManagerReviewEndDate="{ item }">
        {{ formatDatePeriod(item.ManagerReviewEndDate) }}
      </template>
      <template v-slot:item.PerformanceReviewCount="{ item }">
        <v-progress-linear
          color="secondary"
          rounded
          height="25px"
          :model-value="item.CompletedPerformanceReviews"
          :max="item.TotalPerformanceReviews"
        >
        </v-progress-linear>
        <v-sheet
          class="d-flex justify-center">
          {{item.CompletedPerformanceReviews}} / 
          {{item.TotalPerformanceReviews}}        
        </v-sheet>
      </template>
      <template v-slot:item.actions="{ item }">
          <v-btn
            color="primary"
            size="small"
            icon="mdi-eye"
            variant="plain"
            density="compact"
            @click="routeToPerformanceCycle(item.PerformanceCycleID)"
            ><v-icon size="small" icon="mdi-eye" />
            <v-tooltip
              activator="parent"
              location="top"
            >View Performance Cycle
            </v-tooltip>
          </v-btn>
          <v-btn
            color="primary"
            size="small"
            icon="mdi-eye"
            variant="plain"
            density="compact"
            @click="routeToPerformanceReviewList(item.PerformanceCycleID)"
            ><v-icon size="small" icon="mdi-account-group-outline" />
            <v-tooltip
              activator="parent"
              location="top"
            >View Performance Reviews
            </v-tooltip>
          </v-btn>
        </template>
      <template v-slot:bottom> </template>
    </v-data-table>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import authService from "@/services/authService";
import { useRouter } from 'vue-router'
import utils from "@/services/utils";

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const router = useRouter()

const headers = [
  { title: "Period", key: "PerformanceCyclePeriod" },
  { title: "Start Date", key: "ManagerReviewStartDate", value: "ManagerReviewStartDate" },
  { title: "End Date", key: "ManagerReviewEndDate", value: "ManagerReviewEndDate" },
  { title: "Phase", key: "PerformancePhaseName", value: "PerformancePhaseName" },
  { title: "Performance Reviews", key: "PerformanceReviewCount" },
  { title: "Actions", key: "actions", width: "90px", sortable: false },
]

const performanceCycles = ref([]);
const currentId = ref(null);

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

async function getPerformanceCycles() {
  await utils.httpRequest(`/PerformanceCycles`, utils.REQUEST.GET, (data) => {
      performanceCycles.value = data.sort(function(a, b) {
          var x = a["ManagerReviewEndDate"]
          var y = b["ManagerReviewEndDate"]
          return ((x > y) ? -1 : ((x < y) ? 1 : 0))
        })
    }
  )
}

function formatDatePeriod(dateString) {
  return utils.formatDatePeriod(dateString, true);
}

function routeToPerformanceCycle(itemID) {
  router.push({
    name: "performance-cycle-form",
    params: { id: itemID },
  })
}

function routeToPerformanceReviewList(itemID) {
  router.push({
    name: "performance-cycle-performance-reviews",
    params: { id: itemID },
  })
}

function getPerformanceCyclePeriod(key) {
  try {
    return utils.PERFORMANCECYCLEPERIODS[key-1].title
  }
  catch {
    return ""
  }
}

onMounted(async () => {
  emit("updateBreadcrumb", ["Administration", "Performance Cycles"]);
  emit("updateHeader", "Performance Cycles");

  let isAuthorized = await authService.isPerformanceAdmin()
  if (!isAuthorized) {
    emit("updateResultMessage","error|No Access|You are not authorized to view Compensation Cycles");
    router.push({
      name: "not-authenticated"
    })
  }

  getPerformanceCycles();
})
</script>
