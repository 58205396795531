<template>
  <div class="ml-3 mr-6">
    <v-expansion-panels
      rounded="xl">
      <v-expansion-panel
        static
        color="secondary">
        <v-expansion-panel-title>
          <v-row>
            <v-col class="d-flex justify-end">
              Filters and Options
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text
          class="mb-n4">
          <v-row>
            <v-col class="d-flex justify-end">
              <xlsxexportbutton class="mr-2" :rawJSON="filteredItems" :updateMessage="updateResultMessage"></xlsxexportbutton>
              <graphdialog graphType="performancecycleperformancereviewlinegraph" :data="filteredItems" v-if="performanceCycle.PerformancePhaseID >= 2 && performanceReviews"></graphdialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-6 pb-10"></div>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="selectedCountry"
                :disabled="isLoading"
                label="Country"
                density="compact"
                auto-select-first
                clearable
                :items="countries"
                :item-title="item => `${item.CountryName} (${item.CountryCode})`"
                :item-value="item => item.CountryID"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="selectedCompany"
                :disabled="isLoading"
                label="Company"
                density="compact"
                auto-select-first
                clearable
                :items="companies"
                :item-title="item => `${item.CompanyName} (${item.CompanyCode})`"
                :item-value="item => item.CompanyID"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="selectedSilo"
                :disabled="isLoading"
                label="Silo"
                density="compact"
                auto-select-first
                clearable
                :items="silos"
                :item-title="item => `${item.SiloName} (${item.SiloCode})`"
                :item-value="item => item.SiloID"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="selectedPhase"
                clearable
                label="Performance Phases"
                density="compact"
                :items="performancePhases"
                :item-title="item => item.PerformancePhaseName"
                :item-value="item => item.PerformancePhaseID"
                >
              </v-select>
            </v-col>
            <v-col justify="end">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                density="compact"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      density="compact"
      :loading="loading"
      :headers="headers"
      :items="filteredItems"
      items-per-page="100">
      <template v-slot:loading>
        <v-skeleton-loader type="table-row@25"></v-skeleton-loader>
      </template>
      <template v-slot:item.Name="{ item }">
        <v-btn
         class="mt-n1"
         size="small"
         color="secondary"
         icon=""
         variant="text"
         density="compact"
         @click="sendMail(item.EmailAddress)"
       ><v-icon icon="mdi-email" />
        <v-tooltip
          activator="parent"
          location="top"
        >Send mail to {{ item.EmailAddress }}
        </v-tooltip></v-btn>
        {{ item.Name }}
      </template>
      <template v-slot:item.ManagerName="{ item }">
        <v-btn
         v-if="!!item.ManagerName"
         class="mt-n1"
         size="small"
         color="secondary"
         icon=""
         variant="text"
         density="compact"
         @click="sendMail(item.ManagerEmailAddress)"
       ><v-icon icon="mdi-email" />
        <v-tooltip
          activator="parent"
          location="top"
        >Send mail to {{ item.ManagerEmailAddress }}
        </v-tooltip></v-btn>
        {{ item.ManagerName }}
      </template>
      <template v-slot:item.Period="{ item }">
        {{ formatDatePeriod(item.ManagerReviewStartDate) }} - {{ formatDatePeriod(item.ManagerReviewEndDate) }}
      </template>
      <template v-slot:item.PerformancePhaseID="{ item }">
        {{ item.PerformancePhaseName }}
      </template>
      <template v-slot:item.TotalScore="{ item }">
        <v-rating
          v-if="item.TotalScore > 0"
          :model-value="item.TotalScore / (100 / 5)"
          length="5"
          half-increments
          hover
          color="secondary"
          density="compact"
          size="small"
          readonly
        >
        </v-rating>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          color="primary"
          icon=""
          variant="plain"
          density="compact"
          @click="routeToPeformanceReview(item)"
        > <v-icon size="small" icon="mdi-eye" />
          <v-tooltip
            activator="parent"
            location="top"
          >View Performance Review
          </v-tooltip>
        </v-btn>
        <v-btn
          v-if="(item.PerformancePhaseID >= 2) && (performanceCycle.IsActive)"
          color="primary"
          icon=""
          variant="plain"
          density="compact"
          @click="showDialogRollback(item)"
        > <v-icon size="small" icon="mdi-skip-backward" />
          <v-tooltip
            activator="parent"
            location="top"
          >Rollback Performance Review for {{ item.Name }}
          </v-tooltip>
        </v-btn>
        <v-btn
          v-if="item.PerformancePhaseID === 3"
          color="primary"
          icon=""
          variant="plain"
          density="compact"
          @click="getPerformanceReviewPDF(item)"
          > <v-icon size="small" icon="mdi-file-pdf-box" />
          <v-tooltip
            activator="parent"
            location="top"
          >Download Performance Review as PDF
          </v-tooltip>
        </v-btn>
      </template>
    </v-data-table>
  </div>
  
  <v-dialog v-model="dialogRollback" width="auto">
    <v-card>
      <v-card-title class="text-h5">Rollback Performance Review</v-card-title>
      <v-card-text>
        Are you sure you want to Rollback the Performance Review for {{ rollbackItem.Name }}? This cannot be undone!
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" variant="text" @click="rollbackPerformanceReview(rollbackItem)">Yes</v-btn>
        <v-btn color="error" variant="text" @click="closeDialogRollback">No</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <progressdialog v-model="downloading" message="Fetching Performance Review, please hold..."></progressdialog>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import authService from "@/services/authService"
import utils from "@/services/utils"

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const route = useRoute()
const router = useRouter()

const search = ref("")
const headers = [
  { title: "Name", key: "Name" },
  { title: "Manager", key: "ManagerName" },
  { title: "Country", key: "CountryCode", value: "CountryCode" },
  { title: "Company", key: "CompanyCode", value: "CompanyCode" },
  { title: "Silo", key: "SiloCode", value: "SiloCode" },
  { title: "Period", key: "Period" },
  { title: "Phase", key: "PerformancePhaseID" },
  { title: "Score", key: "TotalScore", width: "180px" },
  { title: "Actions", key: "actions", width: "120px", sortable: false },
]
const userID = ref(localStorage.getItem('user_id'))
const performanceReviews = ref([])
const countries = ref([])
const companies = ref([])
const silos = ref([])
const filteredItems = ref([])
const performanceCycle = ref({})
const performancePhases = ref([])
const selectedCountry = ref(null)
const selectedCompany = ref(null)
const selectedSilo = ref(null)
const selectedPhase = ref(null)
const loading = ref(true)
const downloading = ref(false)

const dialogRollback = ref(false)
const rollbackItem = ref(null)

const dialogReport = ref(false)

watch(route.params, (newParams) => {
  getData()
}, { immediate: true })

watch(selectedCountry, (newParams) =>  {
  resetFilter(newParams)
}, { immediate: true })

watch(selectedCompany, (newParams) =>  {
  resetFilter(newParams)
}, { immediate: true })

watch(selectedSilo, (newParams) =>  {
  resetFilter(newParams)
}, { immediate: true })

watch(selectedPhase, (newParams) => {
  resetFilter()
}, { immediate: true })

watch(search, (newParams) => {
  resetFilter()
}, { immediate: true })

function closeDialogRollback () {
  dialogRollback.value = false
}

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

function showDialogRollback(item) {
  rollbackItem.value = Object.assign(item , {})
  dialogRollback.value = true
}
function sendMail(emailAddress) {
  window.open(`mailto:${emailAddress}`)
  updateResultMessage("success|Sending Mail|" + emailAddress)
}

function closeDialogReport() {
  dialogReport.value = false
}

function routeToPeformanceReview(item) {
  let routeData
  if (item.PerformanceReviewID === -1) {
    routeData = router.resolve({
      name: "performance-review-metric",
      params: { id: "new" },
      query: { userid: item.UserID, performancecycleid: item.PerformanceCycleID },
    })
  } else {
    if (item.PerformancePhaseID <= 1 && (item.UserID != localStorage.getItem("user_id"))) {
      routeData = router.resolve({
        name: "performance-review-metric",
        params: { id: item.PerformanceReviewID },
      })
    } else {
      routeData = router.resolve({
        name: "performance-review-detail",
        params: { id: item.PerformanceReviewID },
      })
    }
  }

  window.open(routeData.href, "_blank")
}

async function rollbackPerformanceReview(item) {
  try {
    let payload = {
      PerformanceCycleID: item.PerformanceCycleID,
      UserID: item.UserID
    }
    let uri = `/PerformanceReviews/${item.PerformanceReviewID}/Rollback`

    await utils.httpRequest(uri, utils.REQUEST.POST, (data) => {
        if (!data.Error) {
          updateResultMessage("success|Done|Successfully Rolled back Performance Review")
        } else {
          throw new Error(data.Error.Message)
        }
      },
      payload
    )
    await getPerformanceReviews()
  } catch (e) {
    updateResultMessage("error|Data Issue|" + e.message)
  }
  dialogRollback.value = false
}

async function getPerformanceReviewPDF(item) {
  downloading.value = true
  await utils.httpRequest(`/PerformanceReviews/${item.PerformanceReviewID}/document`, utils.REQUEST.GET, (response) => {
      const url = window.URL.createObjectURL(new Blob([response.blob]))
      const filename = response.filename
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      updateResultMessage("success|Download File|Downloaded Performance Review for " + item.Name)
  })
  downloading.value = false
}

function resetFilter() {
  filteredItems.value = performanceReviews.value.filter(item => {
    let isPhaseMatch = selectedPhase.value != null ? item.PerformancePhaseID === selectedPhase.value : true
    let isCountryMatch = selectedCountry.value ? item.CountryID === selectedCountry.value : true
    let isCompanyMatch = selectedCompany.value ? item.CompanyID === selectedCompany.value : true
    let isSiloMatch = selectedSilo.value ? item.SiloID === selectedSilo.value : true
    let textSearch = utils.jsonObjectHasText(item, search.value)
    return isCountryMatch && isCompanyMatch && isSiloMatch && isPhaseMatch && textSearch
  })
}

function getPerformanceCyclePeriod(key) {
  try {
    return utils.PERFORMANCECYCLEPERIODS[key-1].title
  }
  catch {
    return ""
  }
}

function formatDatePeriod(dateString) {
  return utils.formatDatePeriod(dateString)
}

async function getData() {
  let hasAccess = await authService.isPerformanceAdmin()

  if (hasAccess) {
    await getPerformanceCycle()
    await getCountries()
    await getCompanies()
    await getSilos()
    await getPerformancePhases()
    await getPerformanceReviews()
  }
  else {
    updateResultMessage("error|No Access|You don't have admin role that allows you to access the page")
    router.push({
      name: "home"
    })
  }
}

async function getPerformanceReviews() {
  let uri = `/PerformanceCycles/${route.params.id}/PerformanceReviews`
  await utils.httpRequest(uri, utils.REQUEST.GET, (data) => {
      performanceReviews.value = data
      loading.value = false
    }
  )
  resetFilter() //Always reset date period filter
}

async function getPerformanceCycle() {
  await utils.httpRequest(`/PerformanceCycles/${route.params.id}`, utils.REQUEST.GET, (data) => {
      performanceCycle.value = data
    }
  )

  emit("updateBreadcrumb", ["Performance Cycle", "Administration", formatDatePeriod(performanceCycle.value.ManagerReviewStartDate) + " - " + formatDatePeriod(performanceCycle.value.ManagerReviewEndDate)])
  emit("updateHeader", "Performance Review - " + formatDatePeriod(performanceCycle.value.ManagerReviewStartDate) + " - " + formatDatePeriod(performanceCycle.value.ManagerReviewEndDate))
}

async function getCountries() {
  await utils.httpRequest(`/Static/Countries`, utils.REQUEST.GET, (data) => {
    countries.value = data.filter(item => item.IsActive === true).sort(function(a, b) {
      var x = a["CountryName"]
      var y = b["CountryName"]
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
  })
}

async function getCompanies() {
  await utils.httpRequest(`/Static/Companies`, utils.REQUEST.GET, (data) => {
    companies.value = data.filter(item => item.IsActive === true).sort(function(a, b) {
      var x = a["CompanyName"]
      var y = b["CompanyName"]
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
  })
}

async function getSilos() {
  await utils.httpRequest(`/Static/Silos`, utils.REQUEST.GET, (data) => {
    silos.value = data.filter(item => item.IsActive === true).sort(function(a, b) {
      var x = a["SiloName"]
      var y = b["SiloName"]
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
  })
}

async function getPerformancePhases() {
  await utils.httpRequest(`/PerformancePhases`, utils.REQUEST.GET, (data) => {
      performancePhases.value = data
    }
  )
}

onMounted(async () => {
  emit("updateBreadcrumb", ["Performance Cycle", "Administration"])
  emit("updateHeader", "Performance Review")

  let isAuthorized = await authService.isPerformanceAdmin()
  if (!isAuthorized) {
    emit("updateResultMessage","error|No Access|You are not authorized to view the Performance Cycle Review List");
  }
})
</script>
