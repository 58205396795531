import utils from '@/services/utils'

const userID = localStorage.getItem("user_id")

var localStorageTheme = localStorage.getItem("user_theme") ? JSON.parse(localStorage.getItem("user_theme") || "{}") : {
  DarkMode: false,
  ColourBackground: "#FFFFFF",
  ColourSurface: "#FFFFFF",
  ColourPrimary: "#002D62",
  ColourSecondary: "#739DD2",
  ColourError: "#B71C1C",
  ColourInfo: "#0053B3",
  ColourSuccess: "#2E7D32",
  ColourWarning: "#EF6C00",
}

var logoSrc = localStorage.getItem("user_logo") || require("@/assets/logo.png")

async function getDynamicTheme() {
  let userID = localStorage.getItem("user_id")
  if (userID !== null && userID !== undefined) {
    await utils.httpRequest(`/Users/${userID}/Theme`, utils.REQUEST.GET, (data:any) => {
      logoSrc = data.Logo ? 'data:' + data.LogoMIMEType + ';base64,' + data.Logo : logoSrc
  
      localStorageTheme = data
  
      localStorage.setItem("user_logo", logoSrc)
      localStorage.setItem("user_theme", JSON.stringify(localStorageTheme))
    })
  } else {
    localStorage.setItem("user_logo", require("@/assets/logo.png"))
  }
}

export default {
  localStorageTheme,
  logoSrc,

  getDynamicTheme,
}
