<template>
  <div class="ml-3 mr-6">
    <v-snackbar
      v-model="showWarningScoringNotOpen"
      color="warning"
      timeout="-1"
    >Scoring is not yet open for this Performance Phase
    </v-snackbar>

    <performancereviewuser
      :Name="performanceReview.Name"
      :Period="formatDatePeriod(performanceReview.ManagerReviewStartDate) + ' - ' + formatDatePeriod(performanceReview.ManagerReviewEndDate)"
      :CurrentPhaseID=performanceReview.PerformancePhaseID
      :PerformancePhases="PerformancePhases"
      :Score="performanceReview.TotalScore"
    />

    <v-card
      title="Performance Metrics"
      class="pa-3 mt-4 bg-secondary"
      elevation="4"
      rounded="xl"
      prepend-icon="mdi-medal"
    >
      <v-card-subtitle
          v-html="'Your Performance Metrics should be SMART (Specific,Measurable,Accurate,Relevant and Timely), short and to the point.<br />Your Performance Metrics equate to <strong>' + PerformanceMetricsTotalWeight + '%</strong> of your total score'"
      ></v-card-subtitle>
    </v-card>

    <v-card
      v-for="PerformanceReviewMetric in performanceReviewMetrics"
      class="mt-4 pa-6"
      rounded="xl"
      elevation="4">
      <v-row no-gutters>
        <v-col align="start">
          <v-card-subtitle>
            <pre>{{ PerformanceReviewMetric.MetricDescription }}</pre>
          </v-card-subtitle>
        </v-col>
        <v-col cols="2" align="start">
          <v-card-title align-content="end" class="text-h4 text-right text-primary">
            <strong>{{ PerformanceReviewMetric.PerformanceMetricWeight + "%"}}</strong>
          </v-card-title>
        </v-col>
      </v-row>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="4">
            <div v-for="PerformanceMetric in PerformanceMetrics">
              <input
                type="radio"
                class="radio-button"
                :disabled="isLocked"
                @click="updatePerformanceReviewMetric(PerformanceReviewMetric, 'radio', PerformanceMetric.PerformanceMetricWeight)"
                :checked="PerformanceMetric.PerformanceMetricWeight === PerformanceReviewMetric.PerformanceMetricScore"
                :id="PerformanceReviewMetric.PerformanceReviewMetricScoreID + '-' + PerformanceMetric.PerformanceMetricName"
                :name="'rating-' + PerformanceReviewMetric.PerformanceReviewMetricScoreID"
              />
              <v-label
                :class="getRadioLabelColor(PerformanceMetric.PerformanceMetricID)"
                :for="PerformanceReviewMetric.PerformanceReviewMetricScoreID + '-' + PerformanceMetric.PerformanceMetricName"
                >{{ PerformanceMetric.PerformanceMetricName }}
              </v-label>
            </div>
          </v-col>
          <v-col align="end">
              <v-textarea
                v-model="PerformanceReviewMetric.PerformanceMetricScoreComments"
                :readonly="isLocked"
                :clearable="!isLocked"
                rounded="xl"
                variant="solo"
                @input="updatePerformanceReviewMetric(PerformanceReviewMetric, 'textarea', $event.target.value)"
                label="Additional Comments"
                prepend-inner-icon="mdi-comment"
                counter
                maxlength="1000"
              />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      title="Value Factors"
      class="pa-3 mt-4 bg-secondary"
      elevation="4"
      rounded="xl"
      prepend-icon="mdi-medal"
    >
    <v-card-subtitle
        v-html="'The Value Factors serve as corporate-guided indicators for measuring overall involvement within the organization.<br />Your Value Factors equate to <strong>' + (100 - PerformanceMetricsTotalWeight) + '%</strong> of your total score'"
      ></v-card-subtitle>
    </v-card>

    <v-card
      v-for="PerformanceReviewValueFactor in performanceReviewValueFactors"
      class="mt-4 pa-6"
      rounded="xl"
      elevation="4">
      <v-row no-gutters>
        <v-col align="start">
          <v-card-subtitle>
            <pre>{{ PerformanceReviewValueFactor.PerformanceValueFactorName }}</pre>
          </v-card-subtitle>
        </v-col>
        <v-col cols="2" align="start">
          <v-card-title align-content="end" class="text-h4 text-right text-primary">
            <strong>{{ PerformanceReviewValueFactor.PerformanceValueFactorWeight + "%" }}</strong>
          </v-card-title>
        </v-col>
      </v-row>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="4">
            <div v-for="PerformanceMetric in PerformanceMetrics">
              <input
                type="radio"
                class="radio-button"
                :disabled="isLocked"
                @click="updatePerformanceReviewValueFactor(PerformanceReviewValueFactor, 'radio', PerformanceMetric.PerformanceMetricWeight)"
                :checked="PerformanceReviewValueFactor.PerformanceValueFactorScore === PerformanceMetric.PerformanceMetricWeight"
                :id="PerformanceReviewValueFactor.PerformanceReviewValueFactorScoreID + '-' + PerformanceMetric.PerformanceMetricName"
                :name="'rating-' + PerformanceReviewValueFactor.PerformanceReviewValueFactorScoreID"
              />
              <v-label
                :class="getRadioLabelColor(PerformanceMetric.PerformanceMetricID)"
                :for="PerformanceReviewValueFactor.PerformanceReviewValueFactorScoreID + '-' + PerformanceMetric.PerformanceMetricName"
              >{{ PerformanceMetric.PerformanceMetricName }}
              </v-label>
            </div>
          </v-col>
          <v-col align="end">
            <v-textarea
              :readonly="isLocked"
              :clearable="!isLocked"
              variant="solo"
              rounded="xl"
              @input="updatePerformanceReviewValueFactor(PerformanceReviewValueFactor, 'textarea', $event.target.value)"
              label="Additional Comments"
              prepend-inner-icon="mdi-comment"
              counter
              maxlength="1000"
              v-model="PerformanceReviewValueFactor.PerformanceValueFactorScoreComments"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-textarea
      class="mt-4"
      elevation="8"
      :readonly="isLocked"
      :clearable="!isLocked"
      rounded="xl"
      variant="solo"
      label="Managers Comments"
      prepend-inner-icon="mdi-comment"
      :rules="[!!performanceReview.ManagerComments || 'This field is required']"
      v-model="performanceReview.ManagerComments"
      counter
      maxlength="1500"
    ></v-textarea>

    <v-card
      class="mt-n1 mb-4 pl-6 pr-6"
      elevation="4"
      rounded="xl"
    >
      <v-card-actions>
        <v-btn
          variant="tonal"
          color="primary"
          @click="goBack()"
        >Back</v-btn>
        <v-dialog
          v-if="!isLocked"
          v-model="dialog"
          persistent
          width="auto"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              :disabled="!performanceReview.ManagerComments"
              v-bind="props"
              variant="tonal"
              color="primary">Finalize</v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">Complete this review?</v-card-title>
            <v-card-text>
              By confirming this dialog you are confirming that everything entered is correct
              and you will not be able to change anything after the submission.
              <v-progress-linear
                class="mb-4"
                :active="dialogIsLoading"
                color="primary"
                height="4"
                indeterminate
              ></v-progress-linear>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" :disabled="dialogIsLoading" variant="text" @click="handleSubmit()">Yes</v-btn>
              <v-btn color="error" :disabled="dialogIsLoading" variant="text" @click="dialog = false">No</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="showErrorDialog" width="auto" :persistent="true">
      <v-card>
        <v-card-text>
          {{ errorDialogText }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            block
            @click="
              showErrorDialog = false;
              errorDialogText = '';
            "
            >Close Dialog</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

  <v-dialog v-model="showValidationErrorDialog" width="auto" :persistent="true">
    <v-card>
      <v-card-text>
        {{ validationErrorDialogText }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          block
          @click="
            showValidationErrorDialog = false;
            validationErrorDialogText = '';
          "
          >Close Dialog</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import authService from "@/services/authService"
import utils from "@/services/utils"

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const route = useRoute()
const router = useRouter()

const performanceReviewID = route.params.id
const performanceReview = ref([])
const PerformanceCycle = ref([])
const PerformanceMetrics = ref({})
const performanceReviewMetrics = ref([])
const performanceReviewValueFactors = ref([])

const PerformancePhases = ref([])
const dialog = ref(false)
const dialogIsLoading = ref(false)
const showWarningScoringNotOpen = ref(false)
const showErrorDialog = ref(false)
const errorDialogText = ref("")
const showValidationErrorDialog = ref(false)
const validationErrorDialogText = ref("")
const isLocked = ref(false)

const PerformanceMetricsTotalWeight = ref(0)

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

function formatDatePeriod(dateString) {
  return utils.formatDatePeriod(dateString)
}

function goBack() {
  router.go(-1)
}

function getRadioLabelColor(score) {
  let rtn
  switch(score) {
    case 0:
      rtn = "text-red-accent-4"
      break
    case 1:
      rtn = "text-orange-accent-4"
      break
    case 2:
      rtn = "text-yellow-darken-3"
      break
    case 3:
      rtn = "text-blue-darken-3"
      break
    case 4:
      rtn = "text-teal-darken-3"
      break
    case 5:
      rtn = "text-green-darken-4"
      break
    default:
      rtn = "text-font-weight-bold"
      break
  }
  return "font-weight-bold " + rtn
}

function updatePerformanceReviewMetric(review, fieldType, value) {
  // Find the object with the specified PerformanceReviewID
  const foundObjectIndex = performanceReviewMetrics.value.findIndex(
    (item) =>
      item.PerformanceReviewMetricScoreID === review.PerformanceReviewMetricScoreID
  )
  if (foundObjectIndex !== -1) {
    // Update the values of the found object
    if (fieldType === "radio") {
      performanceReviewMetrics.value[
        foundObjectIndex
      ].PerformanceMetricScore = value
    }
    if (fieldType === "textarea") {
      performanceReviewMetrics.value[
        foundObjectIndex
      ].PerformanceMetricScoreComments = value
    }
  }
}

function updatePerformanceReviewValueFactor(review, fieldType, value) {
  // Find the object with the specified PerformanceReviewID
  const foundObjectIndex = performanceReviewValueFactors.value.findIndex(
    (item) =>
      item.PerformanceReviewValueFactorScoreID ===
      review.PerformanceReviewValueFactorScoreID
  )
  if (foundObjectIndex !== -1) {
    // Update the values of the found object
    if (fieldType === "radio") {
      performanceReviewValueFactors.value[
        foundObjectIndex
      ].PerformanceValueFactorScore = value
    }
    if (fieldType === "textarea") {
      performanceReviewValueFactors.value[
        foundObjectIndex
      ].PerformanceValueFactorScoreComments = value
    }
  }
}

async function getPerformanceReview() {
  try {
    await utils.httpRequest(`/PerformanceReviews/${performanceReviewID}`, utils.REQUEST.GET, (data) => {
        performanceReview.value = data
        if (!data.Error) {
          if (performanceReview.value.PerformancePhaseID <= 1 && (performanceReview.value.ManagerUserID == localStorage.getItem("user_id") || data.IsImpersonation === true) ) {
            router.push({
              name: "performance-review-metric",
              params: route.params.id,
            })
          }
          isLocked.value = (performanceReview.value.PerformancePhaseID != 2) ||
            (showWarningScoringNotOpen.value) ||
            (performanceReview.value.UserID == localStorage.getItem("user_id") && performanceReview.value.IsImpersonation === false) ||
            (performanceReview.value.ManagerUserID != localStorage.getItem("user_id") && performanceReview.value.IsImpersonation === false)// If not in progress
          emit("updateHeader", `Performance Reviews - ${performanceReview.value.Name}`)
          emit("updateBreadcrumb", ["Performance Review", performanceReview.value.Name])
        } else {
        throw new Error(data.Error.Message)
      }
      }
    )
    getPerformanceCycle(performanceReview.value.PerformanceCycleID)
  } catch (e) {
    updateResultMessage("error|Fetch error|" + e.message)
    router.push({
      name: "not-authenticated"
    })
  }
}

async function getPerformanceCycle(id) {
  await utils.httpRequest(`/PerformanceCycles/${id}`, utils.REQUEST.GET, (data) => {
      PerformanceCycle.value = data
      showWarningScoringNotOpen.value = PerformanceCycle.value.PerformancePhaseID < 2 ? true: false
    }
  )
}

async function getPerformanceMetrics() {
  await utils.httpRequest(`/PerformanceMetrics`, utils.REQUEST.GET, (data) => {
      PerformanceMetrics.value = data
    }
  )
}

async function getPerformancePhases() {
  await utils.httpRequest(`/PerformancePhases`, utils.REQUEST.GET, (data) => {
      PerformancePhases.value = data
    }
  )
}

async function handleSubmit() {
  // Validate Metrics
  let isValid = performanceReviewMetrics.value.every((obj) => {
    return (
      typeof obj.PerformanceMetricScore === "number" &&
      obj.PerformanceMetricScore !== null &&
      obj.PerformanceMetricScore !== ""
    )
  })
  // Validates Value factors
  if (isValid) {
    isValid = performanceReviewValueFactors.value.every((obj) => {
      return (
        typeof obj.PerformanceValueFactorScore === "number" &&
        obj.PerformanceValueFactorScore !== null &&
        obj.PerformanceValueFactorScore !== ""
      )
    })
  }
  // Validate Manager comments
  if (isValid) {
    isValid = !!performanceReview.value.ManagerComments
  }
  if (!isValid) {
    showValidationErrorDialog.value = true
    validationErrorDialogText.value = "Please ensure you supply a rating for each metric, value factor as well as a comment in the Managers Comments field."
    dialog.value = false
    dialogIsLoading.value = false
    return
  }
  const postData = JSON.stringify({
    PerformanceReviewMetricScores: performanceReviewMetrics.value,
    PerformanceReviewValueFactorScores: performanceReviewValueFactors.value,
    ManagerComments: performanceReview.value.ManagerComments,
  })

  dialogIsLoading.value = true
  try {
    await utils.httpRequest(`/PerformanceReviews/${performanceReviewID}/Finalize`, utils.REQUEST.POST, (data) => {
      if (!data.Error) {
        router.push({
          name: "performance-review-list",
          params: { type: "current" },
        })
      } else {
        throw new Error(data.Error.Message)
      }
    },
    postData
    )
    updateResultMessage("success|Performance Review|Successfully completed review")
  } catch (e) {
    updateResultMessage("error|Data Issue|" + e.message)
  }
  // Hide confirmation dialog
  dialogIsLoading.value = true
  dialog.value = false
}

async function getPerformanceReviewsMetricsByPerformanceReviewID() {
  await utils.httpRequest(`/PerformanceReviews/${performanceReviewID}/Metrics/`, utils.REQUEST.GET, (data) => {
      performanceReviewMetrics.value = data
    }
  )
}

async function getPerformanceReviewsValueFactorsByPerformanceReviewID() {
  await utils.httpRequest(`/PerformanceReviews/${performanceReviewID}/ValueFactors/`, utils.REQUEST.GET, (data) => {
      performanceReviewValueFactors.value = data
    }
  )
}

async function getPerformanceMetricsTotalWeight() {
  await utils.httpRequest(`/PerformanceValueFactors/`, utils.REQUEST.GET, (data) => {
      PerformanceMetricsTotalWeight.value = data.filter(obj => obj.PerformanceValueFactorID === 0).map(obj => obj.PerformanceValueFactorWeight)
    }
  )
}

onMounted(async () => {
  getPerformanceReview()
  getPerformanceReviewsMetricsByPerformanceReviewID()
  getPerformanceReviewsValueFactorsByPerformanceReviewID()
  getPerformanceMetrics()
  getPerformancePhases()
  getPerformanceMetricsTotalWeight()
})
</script>

<style scoped>
.radio-button {
  display: inline-block;
  margin-right: 10px; /* Add space between the radio button and the text */
  /* Add any other styles you need here */
}
</style>
