<template>
  <Bar id="barchart" :data="chartData" :options="chartOptions" />
</template>
<script setup>
import { onBeforeMount } from "vue"
import { Bar, Line } from "vue-chartjs"
import { Chart as ChartJS, Colors, Title, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement } from "chart.js"
import { useTheme } from "vuetify"

const theme = useTheme()

ChartJS.register(Title, Tooltip, Legend, Colors, CategoryScale, LinearScale, PointElement, LineElement, BarElement)
ChartJS.defaults.font.size = 14

const props = defineProps({
  data: Object,
})

var chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
    },
    tooltip: {
      enabled: true,
      cornerRadius: 12,
      caretSize: 0
    }
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      steps: 10,
      stepValue: 5,
      min: 0
    }
  },
}

var chartData = []
function renderGraph() {
  const numberofBuckets = 20

  const buckets = Array(numberofBuckets + 1).fill(0);

  let totalNumScores = 0
  let totalScore = 0
  let avgScore = 0

  props.data.forEach(item => {
    if (item.TotalScore) {
      const bucketIndex = Math.round(item.TotalScore / (100/numberofBuckets));

      buckets[bucketIndex]++
      totalNumScores++
      totalScore = totalScore + item.TotalScore
    }
  });

  const datasets = []

  datasets[0] = {
      label: "Number of Reviews",
      data: buckets.map((count, index) => ({
        x: (index * Math.round(100/numberofBuckets)).toString()+"%",
        y: count
      }))
  }

  return { datasets }
}

onBeforeMount(() => {
  chartData = renderGraph()
})
</script>
