<template>
  <v-menu v-model="isMenuOpen" :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <v-text-field
        v-bind="props"
        :density="density"
        persistent-placeholder
        :variant="variant"
        readonly
        :loading="loading"
        :label="label"
        v-model="formattedDate"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="selectedDate"
      hide-header
      :disabled="readonly"
      :color="color"
      show-adjacent-months
    >
      <template v-slot:actions>
        <v-btn
          :color="color"
          @click="isMenuOpen = false"
        >Close</v-btn>
      </template>
    </v-date-picker>
  </v-menu>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import utils from '@/services/utils'
import { useDate } from 'vuetify'

const emit = defineEmits(["update:modelValue"])
const props = defineProps({
  modelValue: String,
  density: String,
  color: String,
  variant: String,
  readonly: Boolean,
  label: String,
  loading: Boolean,
})

const isMenuOpen = ref(false)
const initialDate = ref(props.modelValue)
const selectedDate = ref(null)
const dateAdapter = useDate()

onMounted(() => {
  if (initialDate.value) {
    selectedDate.value = new Date(initialDate.value)
  }
})

const formattedDate = computed(() => {
  return utils.formatDatePeriod(selectedDate.value, true)
})

watch(() => props.modelValue, (newDate) => {
  if (newDate) {
    selectedDate.value = new Date(newDate)
  }
})

watch(selectedDate, (newDate) => {
  if (newDate) {
    emit('update:modelValue', dateAdapter.toISO(new Date(newDate)))
  }
})
</script>

<style>
.v-overlay__content:has(> .v-date-picker) {
  min-width: auto!important;
}
.v-picker-title {
  padding: 0 !important;
}
</style>