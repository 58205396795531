import { API_URL } from "@/constants"
import authService from "@/services/authService";
import dayjs from "dayjs";

const REQUEST = {
  GET    : 'GET',
  POST   : 'POST',
  PUT    : 'PUT',
  PATCH  : 'PATCH',
  DELETE : 'DELETE'
}

const LOCALENAMEFROMCURRENCY = {
  'BWP': 'en-BW',
  'GBP': 'en-GB',
  'EUR': 'de-DE',
  'IOM': 'en-IM',
  'KES': 'en-KE',
  'LSL': 'en-LS',
  'MUR': 'en-MU',
  'MWK': 'en-MW',
  'NAD': 'en-NA',
  'SZL': 'en-SZ',
  'UGX': 'en-UG',
  'USD': 'en-US',
  'ZAR': 'en-ZA',
  'ZMW': 'en-ZM',
  'GHS': 'en-GH',
  'RWF': 'en-RW',
  'NGN': 'en-NG'
}

const PERFORMANCECYCLEPERIODS = [
  { value: 1, title: "January" },
  { value: 2, title: "February" },
  { value: 3, title: "March" },
  { value: 4, title: "April" },
  { value: 5, title: "May" },
  { value: 6, title: "June" },
  { value: 7, title: "July" },
  { value: 8, title: "August" },
  { value: 9, title: "September" },
  { value: 10, title: "October" },
  { value: 11, title: "November" },
  { value: 12, title: "December" }
]

function formatDatePeriod(dateString: string, includeDay: boolean = false) {
  if (!dateString) {
      return null;
  }

  const date = dayjs(dateString)
  if (includeDay) {
      return date.format("DD MMMM YYYY")
  }
  else {
      return date.format("MMMM YYYY")
  }
}

async function httpRequest(path: string, method: string, callback?: Function, body?: object): Promise<any> {
  //console.log(`${method} to ${path} with payload ${JSON.stringify(body)}`)
  return await fetch(`${API_URL}${path}`,{
      method: method,
      headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          'Content-Type': 'application/json'
      },
      body: body ? JSON.stringify(body) : undefined
  })
  .then(async (res) => {
    // Check session expired here
    if (res.status === 401) {
      // Handle 401 error here
      console.log('Unauthorized request. Reaquiring auth token')
      if (!sessionStorage.getItem('msal.interaction.status') && await authService.validRefreshToken()) {
        // Key is set in session storage
        console.log('Refresh Token is no longer valid, re-validating')
        await authService.getAccessToken()
        return await httpRequest(path, method, callback, body)
      } else if (!sessionStorage.getItem('msal.interaction.status')) {
        console.log('No refresh token so lets try re-login')                
        await authService.login()
      } else {
        // Key is not set in session storage
        console.log('msal.interaction.status is not set in session storage')
      }
      return null
    }

    const contentType = res.headers.get("Content-Type") || '';
    if(contentType == "application/pdf") {
      const resultBlob = await res.blob()
      const contentDisposition = await res.headers.get("Content-Disposition")
      let resultFilename = "unknownfilename.pdf";
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/i);
        if (filenameMatch && filenameMatch[1]) {
          resultFilename = filenameMatch[1];
        }
      }
      const result = { blob: resultBlob, filename: resultFilename};
      return result;
    } else if(contentType == "application/json") {
      const result = await res.json();
      return result;
    } else {
      const result = await res.text();
      return result;
    }
    })
  .then((data) => {
    if (callback) {
        callback(data)
    }
    return data
  })
  .catch((err) => {
    console.log(`error for ${method} to ${path} =`,err.message)
    throw err
  })
}

function jsonObjectHasText(item: Object, searchTerm: string) {
  let searchTermLower = searchTerm ? searchTerm.toLowerCase(): null
  if (searchTermLower !== null && searchTermLower !== undefined) {
    let hasText = searchTermLower ? Object.values(item).some(value => {
      if (value !== null && value !== undefined) {
        let hasValue = value.toString().toLowerCase().includes(searchTermLower)
        if (hasValue === true) { return true }
      } else {
        return false
      }
    }): true
    return hasText
  } else {
    return true
  }
}

export default {  
  formatDatePeriod,
  httpRequest,
  jsonObjectHasText,
  REQUEST,
  PERFORMANCECYCLEPERIODS,
  LOCALENAMEFROMCURRENCY
}
