import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueCookies from 'vue-cookies'
import authService from '@/services/authService';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import "@mdi/font/css/materialdesignicons.css"
import "@fortawesome/fontawesome-free/css/all.css"
import './assets/css/main.css';

//Register Global Components
import datepicker from "@/components/DatePicker.vue";
import performancereviewuser from '@/components/PerformanceReviewUser.vue'
import xlsxexportbutton from '@/components/XLSXExportButton.vue'
import progressdialog from '@/components/ProgressDialog.vue'

//Register Lookup Components
import cpibands from '@/components/lookups/CPIBands.vue'
import performancevaluefactors from '@/components/lookups/PerformanceValueFactors.vue'
import performancemetrics from '@/components/lookups/PerformanceMetrics.vue'

//Register Graph Components
import graphdialog from '@/components/GraphDialog.vue'
import performancereviewlinegraph from '@/components/graphs/PerformanceReviewLineGraph.vue'
import performancecycleperformancereviewlinegraph from '@/components/graphs/PerformanceCyclePerformanceReviewLineGraph.vue'
import compensationlinegraph from '@/components/graphs/CompensationLineGraph.vue'

await authService.initMsal(); /// Initialize MSAL when the app starts

var dynamicTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#002D62',
    secondary: '#739DD2',
    error: '#B71C1C',
    info: '#0053B3',
    success: '#2E7D32',
    warning: '#EF6C00',
  },
}

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'dynamicTheme',
    themes: {
      dynamicTheme,
    },
  }
})

createApp(App)
  .use(router)
  .use(VueCookies)
  .use(vuetify)

  .component('datepicker', datepicker)
  .component('performancereviewuser', performancereviewuser)
  .component('xlsxexportbutton', xlsxexportbutton)
  .component('progressdialog', progressdialog)
  .component('cpibands', cpibands)
  .component('performancevaluefactors', performancevaluefactors)
  .component('performancemetrics', performancemetrics)

  .component('graphdialog', graphdialog)
  .component('performancereviewlinegraph', performancereviewlinegraph)
  .component('performancecycleperformancereviewlinegraph', performancecycleperformancereviewlinegraph)
  .component('compensationlinegraph', compensationlinegraph)

  .mount('#app')    
