<template>
  <v-app>
    <v-app-bar>
      <v-app-bar-title
        ><img
          alt="Logo"
          cover
          height="50"
          :src="logoSrc"
      />
      </v-app-bar-title>
      <template v-slot:prepend>
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </template>
        <v-menu
        v-if="authenticated"
        open-on-click
        location="start"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            icon="mdi-dots-vertical"
          >
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            prepend-icon="mdi-account"
            :title="fullname"
            :subtitle="username"
          ></v-list-item>

          <v-list-item
            @click="logout()"
            prepend-icon="mdi-logout"
            title="Logout"
          >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn @click="login()" prepend-icon="mdi-login" v-if="!authenticated">Login</v-btn>
    </v-app-bar>

    <v-main :key="mainKeyRender">
      <v-navigation-drawer
        :key="mainKeyRender"
        v-if="authenticated"
        v-model="drawer"
        temporary
        fluid
      >
        <v-list
          nav
          density="compact"
          color="primary"
        >
          <v-list-subheader>PERFORMANCE REVIEWS MODULE</v-list-subheader>
          <v-list-item
              prepend-icon="mdi-medal-outline"
              title="My Reviews"
              :to="{ name: 'performance-review-list', params: { type: 'mine' } }"
              value="myreviews"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-account-group-outline"
              title="Staff Reviews"
              :to="{ name: 'performance-review-list', params: { type: 'current' } }"
              value="currentreviews"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-history"
              title="Historic Reviews"
              :to="{ name: 'performance-review-list', params: { type: 'history' } }"
              value="historicreviews"
            ></v-list-item>
          <v-divider></v-divider>

          <v-list-subheader>COMPENSATION MODULE</v-list-subheader>
          <v-list-item
              prepend-icon="mdi-account-cash-outline"
              title="My Compensation"
              :to="{ name: 'compensation-list', params: { type: 'mine' } }"
              value="mycompensation"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-cash-multiple"
              title="Staff Compensation"
              :to="{ name: 'compensation-list', params: { type: 'current' } }"
              value="staffcompensation"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-cash-multiple"
              title="Historic Compensation"
              :to="{ name: 'compensation-list', params: { type: 'history' } }"
              value="historiccompensation"
            ></v-list-item>
            <v-list-item
              v-if="isRemCo"
              prepend-icon="mdi-cash"
              title="Remuneration Committee"
              :to="{ name: 'compensation-list', params: { type: 'remco' } }"
              value="remco"
            ></v-list-item>
          <v-divider></v-divider>

          <v-list-subheader
          v-if="isAdmin"
          >ADMINISTRATION</v-list-subheader>
            <v-list-item
              title="Lookups"
              v-if="isPerformanceAdmin || isCompensationAdmin"
              :to="{ name: 'lookups' }"
              value="lookups"
              prepend-icon="mdi-list-status"
            ></v-list-item>
            <v-list-item
              title="Performance Cycles"
              v-if="isPerformanceAdmin"
              :to="{ name: 'performance-cycle' }"
              prepend-icon="mdi-calendar-clock"
            ></v-list-item>
            <v-list-item
              title="Compensation Cycles"
              v-if="isCompensationAdmin"
              :to="{ name: 'compensation-cycle' }"
              prepend-icon="mdi-cash-clock"
            ></v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-container fluid>
        <v-row>
          <v-col>
            <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
          </v-col>
          <v-col class="mr-2" cols="1" align="right">
            <v-btn @click="reloadpage" rounded icon="mdi-refresh"></v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar
        v-model="hasResultMessage"
        :color="resultMessageType"
        vertical>
        <div class="text-subtitle-1 pb-2">{{ resultMessageTitle }}</div>
        <div>{{ resultMessage }}</div>
      </v-snackbar>
      <router-view
        :key="$route.fullPath"
        @updateHeader="updateHeader"
        @updateBreadcrumb="updateBreadcrumb"
        @updateResultMessage="updateResultMessage"
      />
    </v-main>
  </v-app>
</template>

<script setup>
import authService from "@/services/authService"
import utils from "@/services/utils"
import themeStore from "@/services/themeStore"
import { useTheme } from "vuetify"
import { ref, onMounted, watch } from "vue"
import { useRouter } from 'vue-router'

const mainKeyRender = ref(0)
const theme = useTheme()
const router = useRouter()
const breadcrumbs = ref([])
const userid = ref()
const username = ref()
const fullname = ref()
const authenticated = ref(false)
const isAdmin = ref(false)
const isPerformanceAdmin = ref(false)
const isCompensationAdmin = ref(false)
const isRemCo = ref(false)
const drawer = ref(false)

var logoSrc = ref(require("./assets/logo.png"))
var localStorageDynamicTheme = ref({})

var hasResultMessage = ref(false)
var resultMessageType = ref(null)
var resultMessageTitle = ref(null)
var resultMessage = ref(null)

function updateHeader(msg) {
  let m = msg ? " - " + msg : ""
  document.title = "Performance Review " + m
}

const updateBreadcrumb = (data) => {
  breadcrumbs.value = data
}

const updateResultMessage = (data) => {
  const parts = data.split("|")
  if (parts.length !== 3) {
    throw new Error("Data does not consist of 3 parts")
  }
  hasResultMessage.value = true
  resultMessageType.value = parts[0]
  resultMessageTitle.value = parts[1]
  resultMessage.value = parts[2]
  const validTypes = ["success", "info", "warning", "error"]
  if (!validTypes.includes(resultMessageType.value)) {
    throw new Error("Invalid result message type")
  }
  let timeout = (resultMessageType.value == "error") ? 10000 : 3000
  setTimeout(() => {
    hasResultMessage.value = false
  }, timeout)
}

function updateUserDetails() {
  fullname.value = localStorage.getItem("fullname")
  username.value = localStorage.getItem("username")
}

async function updateAuthModules() {
  isPerformanceAdmin.value = await authService.isPerformanceAdmin()
  isCompensationAdmin.value = await authService.isCompensationAdmin()
  isAdmin.value = isPerformanceAdmin.value || isCompensationAdmin.value
  isRemCo.value = await authService.isRemCo()
  mainKeyRender.value += 1;
}

async function setAppConfig() {
  updateUserDetails()
  await getTheme()
  await updateAuthModules()
}

async function login() {
  authenticated.value = await authService.login()
  if (authenticated.value === true) {
    await setAppConfig()
    router.push({
        name: "performance-review-list",
        params: { type: 'current' }
      })
  } else {
    router.push({
        name: "not-authenticated"
      })
  }
}

async function logout() {
  await authService.logout()
}

function reloadpage() {
  window.location.reload()
}

function setLogo() {
  if (localStorage.getItem("user_logo")) {
    logoSrc.value = localStorage.getItem("user_logo")
  }
}

function setTheme() {
  if (localStorage.getItem("user_theme")) {
    let dynamicTheme = JSON.parse(localStorage.getItem("user_theme"))
  
    theme.themes.value.dynamicTheme.dark = dynamicTheme.DarkMode
    theme.themes.value.dynamicTheme.colors.background = dynamicTheme.ColourBackground
    theme.themes.value.dynamicTheme.colors.surface = dynamicTheme.ColourSurface
    theme.themes.value.dynamicTheme.colors.primary = dynamicTheme.ColourPrimary
    theme.themes.value.dynamicTheme.colors.secondary = dynamicTheme.ColourSecondary
    theme.themes.value.dynamicTheme.colors.error = dynamicTheme.ColourError
    theme.themes.value.dynamicTheme.colors.info = dynamicTheme.ColourInfo
    theme.themes.value.dynamicTheme.colors.success = dynamicTheme.ColourSuccess
    theme.themes.value.dynamicTheme.colors.warning = dynamicTheme.ColourWarning

    theme.global.name.value = "dynamicTheme"
  }
}

async function getTheme() {
  setTheme()
  setLogo()
  await themeStore.getDynamicTheme()
  localStorageDynamicTheme.value = localStorage.getItem("user_theme")
  logoSrc.value = localStorage.getItem("user_logo")
}

watch (localStorageDynamicTheme, (newValue, oldValue) => {
  setTheme()
})

watch (logoSrc, (newValue, oldValue) => {
  setLogo()
})

onMounted(async () => {
  authenticated.value = await authService.isAuthenticated()
  if (authenticated.value === true) {
    await setAppConfig()
  }
})
</script>
