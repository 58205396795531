<template>
  <div class="ml-3 mr-6">
    <v-expansion-panels
      rounded="xl">
      <v-expansion-panel
        static
        color="secondary">
        <v-expansion-panel-title>
          <v-row>
            <v-col class="d-flex justify-end">
              Filters and Options
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text
          class="mb-n4">
          <v-row>
            <v-col class="d-flex justify-end">
              <xlsxexportbutton class="mr-2" :rawJSON="compensationCycles" :updateMessage="updateResultMessage"></xlsxexportbutton>
              <v-btn
                variant="tonal"
                color="primary"
                icon=""
                @click="routeToCompensationCycle(`new`)"
                >
                <v-icon icon="mdi-plus-thick" />
                <v-tooltip
                  activator="parent"
                  location="top"
                >Add New Cycle
                </v-tooltip>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col></v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      density="compact"
      v-if="compensationCycles.length != 0"
      :loading="loading"
      :headers="headers"
      :items="compensationCycles"
      items-per-page="-1"
      hide-default-footer="true">
      <template v-slot:loading>
        <v-skeleton-loader type="table-row@5"></v-skeleton-loader>
      </template>
      <template v-slot:item.CompensationCyclePeriod="{ item }">
        <v-icon
          v-if="item.IsActive"
          size="small"
          icon="mdi-check"
          color="success"
        ></v-icon>
        <v-icon
          v-if="! item.IsActive"
          size="small"
          icon="mdi-cancel"
          color="error"
        ></v-icon>
        {{ item.Year }} - {{ getPerformanceCyclePeriod(item.Period) }}
      </template>
      <template v-slot:item.CompensationStartDate="{ item }">
        {{ formatDatePeriod(item.CompensationStartDate) }}
      </template>
      <template v-slot:item.CompensationEndDate="{ item }">
        {{ formatDatePeriod(item.CompensationEndDate) }}
      </template>
      <template v-slot:item.LengthOfServiceThreshold="{ item }">
          {{item.LengthOfServiceThreshold}} Months
      </template>
      <template v-slot:item.CalculationThreshold="{ item }">
        <div>
        <v-progress-linear
          color="secondary"
          rounded
          height="25px"
          :model-value="item.CalculationThreshold"
          :max="100"
        >
        </v-progress-linear>
        </div>
        <v-sheet
          class="d-flex justify-center">
          {{item.CalculationThreshold}} %
        </v-sheet>
      </template>
      <template v-slot:item.actions="{ item }">
          <v-btn
            color="primary"
            icon="mdi-eye"
            size="small"
            variant="plain"
            density="compact"
            @click="routeToCompensationCycle(item.CompensationCycleID)"
            ><v-icon size="small" icon="mdi-eye" />
            <v-tooltip
              activator="parent"
              location="top"
            >View Performance Reviews
            </v-tooltip>
          </v-btn>

            <v-btn
            color="primary"
            size="small"
            icon=""
            variant="plain"
            density="compact"
            @click="routeToCompensationList(item.CompensationCycleID)"
            ><v-icon size="small" icon="mdi-account-group-outline" />
            <v-tooltip
              activator="parent"
              location="top"
            >View Compensations
            </v-tooltip>
          </v-btn>
        </template>
      <template v-slot:bottom> </template>
    </v-data-table>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import authService from '@/services/authService';
import utils from '@/services/utils';

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const router = useRouter()

const headers = [
    { title: "Period", key: "CompensationCyclePeriod" },
    { title: "Type", key: "CompensationTypeName", value: "CompensationTypeName" },
    { title: "Start Date", key: "CompensationStartDate", value: "CompensationStartDate" },
    { title: "End Date", key: "CompensationEndDate", value: "CompensationEndDate" },
    { title: "Length of Service", key: "LengthOfServiceThreshold", value: "LengthOfServiceThreshold" },
    { title: "Min. Review Calculation", key: "CalculationThreshold", value: "CalculationThreshold" },
    { title: "Performance Reviews", key: "NumberOfPerformanceReviewsInCycle", key: "NumberOfPerformanceReviewsInCycle" },
    { title: "Actions", key: "actions", width: "90px", sortable: false },
];

const compensationCycles = ref([]);
const currentId = ref(null);
const loading = ref(true);

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

async function getCompensationCycles() {
    await utils.httpRequest('/CompensationCycles', utils.REQUEST.GET, (data) => {
        compensationCycles.value = data.sort(function(a, b) {
          var x = a["CompensationEndDate"]
          var y = b["CompensationEndDate"]
          return ((x > y) ? -1 : ((x < y) ? 1 : 0))
        });
        loading.value = false;
    });
}

function formatDatePeriod(dateString) {
    return utils.formatDatePeriod(dateString, true);
}

function routeToCompensationCycle(itemID) {
  router.push({
    name: 'compensation-cycle-form',
    params: { id: itemID },
  })
}

function routeToCompensationList(itemID) {
  router.push({
    name: "compensation-cycle-compensation",
    params: { id: itemID },
  })
}

function getPerformanceCyclePeriod(key) {
    try {
        return utils.PERFORMANCECYCLEPERIODS[key - 1].title;
    } catch {
        return '';
    }
}

onMounted(async () => {
  emit("updateBreadcrumb", ["Administration", "Compensation Cycles"]);
  emit("updateHeader", "Compensation Cycles");

  let isAuthorized = await authService.isCompensationAdmin()
  if (!isAuthorized) {
    emit('updateResultMessage','error|No Access|You are not authorized to view Compensation Cycles');
    router.push({
      name: "not-authenticated"
    })
  }

  getCompensationCycles();
});
</script>