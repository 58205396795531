<template>
  <div class="ml-3 mr-6">
    <v-expansion-panels
      rounded="xl">
      <v-expansion-panel
        static
        color="secondary">
        <v-expansion-panel-title>
          <v-row>
            <v-col class="d-flex justify-end">
              Filters and Options
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text
          class="mb-n4">
          <v-row>
            <v-col class="d-flex justify-end">
              <xlsxexportbutton class="mr-2" :rawJSON="filteredItems" :updateMessage="updateResultMessage"></xlsxexportbutton>
              <graphdialog graphType="compensationlinegraph" :data="filteredItems" ></graphdialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <div class="mb-6 pb-10"></div>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="selectedCountry"
                :disabled="isLoading"
                label="Country"
                density="compact"
                auto-select-first
                clearable
                :items="countries"
                :item-title="item => `${item.CountryName} (${item.CountryCode})`"
                :item-value="item => item.CountryID"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="selectedCompany"
                :disabled="isLoading"
                label="Company"
                density="compact"
                auto-select-first
                clearable
                :items="companies"
                :item-title="item => `${item.CompanyName} (${item.CompanyCode})`"
                :item-value="item => item.CompanyID"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="selectedSilo"
                :disabled="isLoading"
                label="Silo"
                density="compact"
                auto-select-first
                clearable
                :items="silos"
                :item-title="item => `${item.SiloName} (${item.SiloCode})`"
                :item-value="item => item.SiloID"
              >
              </v-autocomplete>
            </v-col>
            <v-col justify="end">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                density="compact"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n6">
            <v-col cols="1">
              <div class="mb-6 pb-10"></div>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-switch
                class="pr-8"
                v-model="selectedReviewed"
                :disabled="isLoading"
                variant="solo"
                label="Reviewed"
                density="compact"
                color="primary"
              >
              </v-switch>
              <v-switch
                class="pr-8"
                v-model="selectedEligible"
                :disabled="isLoading"
                variant="solo"
                label="Eligible Only"
                density="compact"
                color="primary"
              >
              </v-switch>
              <v-switch
                v-model="selectedLastRemCoIsFlagged"
                :disabled="isLoading"
                variant="solo"
                label="Flagged"
                density="compact"
                color="primary"
              >
              </v-switch>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      density="compact"
      :loading="isLoading"
      v-model="selected"
      :headers="headers"
      :items="filteredItems"
      item-value="CompensationID"
      items-per-page="50">
      <template v-slot:loading>
        <v-skeleton-loader type="table-row@25"></v-skeleton-loader>
      </template>
      <template v-slot:item.CompensationCycle="{ item }">
        {{ item.CompensationYear }} - {{ getPerformanceCyclePeriod(item.CompensationPeriod) }} ({{ item.CompensationTypeName }})
      </template>      
      <template v-slot:item.Name="{ item }">
        <v-btn
         class="mt-n1"
         size="small"
         color="secondary"
         icon=""
         variant="text"
         density="compact"
         @click="sendMail(item.EmailAddress)"
       ><v-icon icon="mdi-email" />
        <v-tooltip
          activator="parent"
          location="top"
        >Send mail to {{ item.EmailAddress }}
        </v-tooltip></v-btn>
        {{ item.Name }}
      </template>
      <template v-slot:item.ManagerName="{ item }">
        <v-btn
         class="mt-n1"
         size="small"
         color="secondary"
         icon=""
         variant="text"
         density="compact"
         @click="sendMail(item.ManagerEmailAddress)"
       ><v-icon icon="mdi-email" />
        <v-tooltip
          activator="parent"
          location="top"
        >Send mail to {{ item.ManagerEmailAddress }}
        </v-tooltip></v-btn>
        {{ item.ManagerName }}
      </template>
      <template v-slot:item.CalculatedTotalScore="{ item }">
        <div class="d-flex justify-end">
          {{ item.CalculatedTotalScore ? item.CalculatedTotalScore + "% (" + item.PerformanceReviewsInCycle + ")" : '' }}
        </div>
      </template>
      <template v-slot:item.CurrentRemuneration="{ item }">
        <div class="d-flex justify-end">
          {{ item.CurrentRemuneration ? item.CurrentRemuneration.toLocaleString(getLocaleFromCurrency(item.CurrencyCode), {style: 'currency', currency: item.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "" }}
        </div>
      </template>
      <template v-slot:item.ProposedRemuneration="{ item }">
        <div class="d-flex justify-end">
          {{ item.ProposedRemuneration ? item.ProposedRemuneration.toLocaleString(getLocaleFromCurrency(item.CurrencyCode), {style: 'currency', currency: item.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2}) + (compensationCycle.CompensationTypeID === 0 ? " (" + item.ProposedInflationRate + "%)": ""): "" }}
          <v-icon
          class="ml-2"
          v-if="item.ProposedRemuneration < item.CalculatedRemuneration"
          size="small"
          icon="mdi-arrow-down"
          color="error"
        ></v-icon>
        <v-icon
          v-if="item.ProposedRemuneration == item.CalculatedRemuneration"
          size="small"
          icon="mdi-minus"
          color="primary"
        ></v-icon>
        <v-icon
          v-if="item.CalculatedRemuneration < item.ProposedRemuneration"
          size="small"
          icon="mdi-arrow-up"
          color="success"
        ></v-icon>
        </div>
      </template>
      <template v-slot:item.CalculatedRemuneration="{ item }">
        <div class="d-flex justify-end">
          {{ item.CalculatedRemuneration ? item.CalculatedRemuneration.toLocaleString(getLocaleFromCurrency(item.CurrencyCode), {style: 'currency', currency: item.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2}) + (compensationCycle.CompensationTypeID === 0 ? " (" + item.CalculatedInflationRate + "%)": "") :"" }}
        </div>
      </template>
      <template v-slot:item.FinalRemuneration="{ item }">
        <div class="d-flex justify-end">
          {{ item.FinalRemuneration ? item.FinalRemuneration.toLocaleString(getLocaleFromCurrency(item.CurrencyCode), {style: 'currency', currency: item.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2}) + (compensationCycle.CompensationTypeID === 0 ? " (" + item.FinalInflationRate + "%)": ""): "" }}
        </div>
      </template>
      <template v-slot:item.notifications="{ item }">
        <v-btn
          v-if="item.IsEligibleForRemuneration"
          color="success"
          icon=""
          variant="plain"
          density="compact"
        ><v-icon size="small" icon="mdi-emoticon-happy-outline" />
          <v-tooltip
            activator="parent"
            location="top"
          >Eligible
          </v-tooltip>
        </v-btn>
        <v-btn
          v-if="!item.IsEligibleForRemuneration"
          color="error"
          icon=""
          variant="plain"
          density="compact"
        ><v-icon size="small" icon="mdi-emoticon-sad-outline" />
          <v-tooltip
            activator="parent"
            location="top"
          >Not Eligible: {{ item.NonEligibilityReason }}
          </v-tooltip>
        </v-btn>
        <v-btn
          v-if="item.LastRemCoIsFlagged"
          color="primary"
          icon=""
          variant="plain"
          density="compact"
        > <v-icon size="small" icon="mdi-exclamation" />
          <v-tooltip
            activator="parent"
            location="top"
          >This Remuneration has been flagged
          </v-tooltip>
        </v-btn>
        <v-btn
          v-if="item.CompensationManagerCommentID"
          color="warning"
          icon=""
          variant="plain"
          density="compact"
        > <v-icon size="small" icon="mdi-bell" />
          <v-tooltip
            activator="parent"
            location="top"
          >This Remuneration is still under review by {{ item.AssignedManagerName }}
          </v-tooltip>
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          color="primary"
          icon=""
          variant="plain"
          density="compact"
          @click="routeToCompensationDetail(item)"
        ><v-icon size="small" icon="mdi-eye" />
          <v-tooltip
            activator="parent"
            location="top"
          >View Compensation Review
          </v-tooltip>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import authService from "@/services/authService"
import utils from "@/services/utils"

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const route = useRoute()
const router = useRouter()

const isLoading = ref(true)
const search = ref("")
const selected = ref([])
const zeroRemuneration = ref(0)

var headers = [
  { title: "Name", key: "Name", value: "Name" },
  { title: "Manager", key: "ManagerName", value: "ManagerName" },
  { title: "Country", key: "CountryCode", value: "CountryCode" },
  { title: "Company", key: "CompanyCode", value: "CompanyCode" },
  { title: "Silo", key: "SiloCode", value: "SiloCode" },
  { title: "Avg Score", key: "CalculatedTotalScore", value: "CalculatedTotalScore", align: "center", width: "125px" },
  { title: "Current CTC", key: "CurrentRemuneration", value: "CurrentRemuneration", align: "center", width: "180px" },
  { title: "System Generated", key: "CalculatedRemuneration", value: "CalculatedRemuneration", align: "end", width: "180px" },
  { title: "MANCO Proposed", key: "ProposedRemuneration", value: "ProposedRemuneration", align: "end", width: "180px" },
  { title: "Final", key: "FinalRemuneration", value: "FinalRemuneration", align: "end", width: "180px" },
  { title: "", key: "notifications", width: "120px", sortable: false },
  { title: "Actions", key: "actions", width: "90px", sortable: false }
]

const selectedCountry = ref(null)
const selectedCompany = ref(null)
const selectedSilo = ref(null)
const selectedEligible = ref(null)
const selectedReviewed = ref(false)
const selectedLastRemCoIsFlagged = ref(null)

const compensationCycle = ref([])
const compensations = ref([])
const countries = ref([])
const companies = ref([])
const silos = ref([])
const filteredItems = ref([])
const editedIndex = ref(-1)
const editedItem = ref([])
const editedItemManagerComments = ref([])
const rulesIsRequired = [v => !!v || "Value is required"]

const isOverrideValid = ref(false)
var overrideCompensationCompensationID = ref(null)
var overrideCompensationManagerInflationRate = ref(null)
var overrideCompensationManagerRemuneration = ref(null)
var overrideCompensationManagerComments = ref(null)

const dialogReport = ref(false)

function overrideCompensation() {
  overrideCompensationManagerRemuneration = (editedItem.value.CurrentRemuneration) + (editedItem.value.CurrentRemuneration * overrideCompensationManagerInflationRate / 100)
}

watch(route.params, (newParams) => {
  getData()
}, { immediate: true })

watch(selectedEligible, (newParams) => {
  resetFilter(newParams)
}, { immediate: true })

watch(selectedReviewed, (newParams) => {
  resetFilter(newParams)
}, { immediate: true })

watch(selectedCountry, (newParams) =>  {
  resetFilter(newParams)
}, { immediate: true })

watch(selectedCompany, (newParams) =>  {
  resetFilter(newParams)
}, { immediate: true })

watch(selectedSilo, (newParams) =>  {
  resetFilter(newParams)
}, { immediate: true })

watch(selectedLastRemCoIsFlagged, (newParams) =>  {
  resetFilter(newParams)
}, { immediate: true })

watch(search, (newParams) =>  {
  resetFilter(newParams)
}, { immediate: true })

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

function sendMail(emailAddress) {
  window.open(`mailto:${emailAddress}`)
  updateResultMessage("success|Sending Mail|" + emailAddress)
}

function resetFilter() {
  isLoading.value = true
  filteredItems.value = compensations.value.filter(item => {
    let isReviewedMatch = selectedReviewed.value ? item.AssignedManagerID === null: true
    let isEligibleMatch = selectedEligible.value ? item.IsEligibleForRemuneration === selectedEligible.value : true
    let isCountryMatch = selectedCountry.value ? item.CountryID === selectedCountry.value : true
    let isCompanyMatch = selectedCompany.value ? item.CompanyID === selectedCompany.value : true
    let isSiloMatch = selectedSilo.value ? item.SiloID === selectedSilo.value : true
    let isLastRemCoIsFlaggedMatch = selectedLastRemCoIsFlagged.value ? item.LastRemCoIsFlagged === selectedLastRemCoIsFlagged.value : true
    let textSearch = utils.jsonObjectHasText(item, search.value)
    return isEligibleMatch && isReviewedMatch && isCountryMatch && isCompanyMatch && isSiloMatch && isLastRemCoIsFlaggedMatch && textSearch
  })
  selected.value = []
  isLoading.value = false
}

function formatDatePeriod(dateString) {
  return utils.formatDatePeriod(dateString)
}

function getLocaleFromCurrency(key) {
  const rtn = utils.LOCALENAMEFROMCURRENCY[key] || undefined
  return rtn
}

function closeDialogReport() {
  dialogReport.value = false
}

function getPerformanceCyclePeriod(key) {
  try {
    return utils.PERFORMANCECYCLEPERIODS[key-1].title
  }
  catch {
    return ""
  }
}

async function getData() {
  let hasAccess = await authService.isCompensationAdmin()

  if (hasAccess) {
    await getCompensationCycle()
    await getCountries()
    await getCompanies()
    await getSilos()
    await getCompensations()
  }
  else {
    updateResultMessage("error|No Access|You don't have admin role that allows you to access the page")
    router.push({
      name: "home"
    })
  }
}

async function getCompensationCycle() {
  await utils.httpRequest(`/CompensationCycles/${route.params.id}`, utils.REQUEST.GET, (data) => {
      compensationCycle.value = data
      if (compensationCycle.value.CompensationTypeID === 2) { //If Bonus, remove calculated remuneration
        headers = headers.filter(item => item.key !== "CalculatedRemuneration")
      }
    }
  )

  emit("updateBreadcrumb", ["Compensation Cycle", "Administration", formatDatePeriod(compensationCycle.value.CompensationStartDate) + " - " + formatDatePeriod(compensationCycle.value.CompensationEndDate)])
  emit("updateHeader", "Compensation Cycle - " + formatDatePeriod(compensationCycle.value.CompensationStartDate) + " - " + formatDatePeriod(compensationCycle.value.CompensationEndDate))
}

async function getCompensations() {
  isLoading.value = true
  let uri = `/CompensationCycles/${route.params.id}/Compensation`

  await utils.httpRequest(uri, utils.REQUEST.GET, (data) => {
    compensations.value = data
    filteredItems.value = compensations.value
  })
  resetFilter() //Always filter IsEligible
  isLoading.value = false
}

async function getCountries() {
  await utils.httpRequest(`/Static/Countries`, utils.REQUEST.GET, (data) => {
    countries.value = data.filter(item => item.IsActive === true).sort(function(a, b) {
      var x = a["CountryName"]
      var y = b["CountryName"]
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
  })
}

async function getCompanies() {
  await utils.httpRequest(`/Static/Companies`, utils.REQUEST.GET, (data) => {
    companies.value = data.filter(item => item.IsActive === true).sort(function(a, b) {
      var x = a["CompanyName"]
      var y = b["CompanyName"]
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
  })
}

async function getSilos() {
  await utils.httpRequest(`/Static/Silos`, utils.REQUEST.GET, (data) => {
    silos.value = data.filter(item => item.IsActive === true).sort(function(a, b) {
      var x = a["SiloName"]
      var y = b["SiloName"]
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
  })
}

function routeToCompensationDetail (item) {
  const routeData = router.resolve({
    name: "compensation-detail",
    params: {
      id: item.CompensationID
      }
  })
  window.open(routeData.href, "_blank")
  }
</script>
