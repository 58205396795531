<template>
  <v-btn
    variant="tonal"
    color="primary"
    icon=""
    @click="downloadFile()"
  >
    <v-icon icon="mdi-file-excel" />
      <v-tooltip
        activator="parent"
        location="top"
      >Export to Excel
      </v-tooltip>
  </v-btn>
</template>
<script setup>
import { toRef } from 'vue'
import { writeFile, utils } from 'xlsx'

const props = defineProps({
  rawJSON: Object,
  updateMessage: Function,
})

const rawJSON = toRef(props, 'rawJSON')

function updateResultMessage(message) {
  props.updateMessage(message)
}

async function downloadFile() {
  let length = rawJSON.value.length
  if (length > 0) {
    let ws = utils.json_to_sheet(rawJSON.value)
    let wb = utils.book_new()
    utils.book_append_sheet(wb, ws, 'Export')
    writeFile(wb, 'export.xlsx')
  
    updateResultMessage("success|Excel Export|Successfully exported "+ rawJSON.value.length + " records")
  } else {
    updateResultMessage("warning|Excel Export|No records to export")
  }
}
</script>
