<template>
  <Line id="linechart" :data="chartData" :options="chartOptions" />
</template>
<script setup>
import { onBeforeMount } from "vue"
import { Bar, Line } from "vue-chartjs"
import { Chart as ChartJS, Colors, Title, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement } from "chart.js"
import { useTheme } from "vuetify"

const theme = useTheme()

ChartJS.register(Title, Tooltip, Legend, Colors, CategoryScale, LinearScale, PointElement, LineElement, BarElement)
ChartJS.defaults.font.size = 14

const props = defineProps({
  data: Object,
})

var chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
    },
    tooltip: {
      enabled: true,
      cornerRadius: 12,
      caretSize: 0,
    }
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      steps: 10,
      stepValue: 5,
      min: 0,
      max: 100
    }
  },
}

var chartData = []
function renderGraph() {
  let scoredPRs = props.data.reduce((acc, item) => {
    if (!acc[item.Name]) {
      acc[item.Name] = []
    }
    acc[item.Name].push(item)
    return acc
  }, {})

  const datasets = []
  for (let s in scoredPRs) {
    scoredPRs[s].sort(function(a, b) {
      var x = (new Date( a["ManagerReviewEndDate"]))
      var y = (new Date( b["ManagerReviewEndDate"]))
      return ((x < y) ? -1 : ((x < y) ? 1 : 0))
    })

    datasets.push({
      label: s,
      data: scoredPRs[s].map(item => ({ x: new Date(item.ManagerReviewEndDate).toLocaleDateString(), y: item.TotalScore }))
      })
  }

  return { datasets }
}

onBeforeMount(() => {
  chartData = renderGraph()
})
</script>
