<template>
  <v-dialog v-model="dialogReport" width="60%">
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn
        v-bind="activatorProps"
        icon=""
        variant="tonal"
        color="primary"
        >
        <v-icon icon="mdi-finance" />
        <v-tooltip
          activator="parent"
          location="top"
        >Show Report
        </v-tooltip>
      </v-btn>
    </template>
    <template v-slot:default="{ dialogReport }">
      <v-card rounded="xl">
        <v-card-text class="ma-2"> 
          <component :is="graphType" :data="data"></component>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mb-4 mr-4"
            variant="tonal"
            color="primary"
            @click="closeDialogReport()"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script setup>
import { ref, onBeforeMount } from 'vue'

const props = defineProps({
  graphType: Object,
  data: Object,
})

const dialogReport = ref(false)

function closeDialogReport() {
  dialogReport.value = false
}

onBeforeMount(() => {
})

</script>
