<template>
  <div class="ml-3 mr-6">
    <v-card
      class="bg-primary"
      rounded="xl"
      elevation="4"
      height="230px"
      style="overflow-y: auto"
    >
      <v-card-title class="ml-4 mt-4">{{ compensationItem.Name }} ({{ compensationItem.EmailAddress }})</v-card-title>
      <v-card-subtitle class="ml-4">{{ compensationItem.CompensationYear }} - {{ getPerformanceCyclePeriod(compensationItem.CompensationPeriod) }} {{ compensationItem.CompensationTypeName }}</v-card-subtitle>
      <v-card-text class="text-body-1 ml-4">
        <v-row class="ml-4 mt-n4">
          <v-col class="font-weight-bold">Manager</v-col>
          <v-col>
            <v-btn
              class="mt-n1"
              size="small"
              color="secondary"
              icon=""
              variant="text"
              density="compact"
              @click="sendMail(compensationItem.ManagerEmailAddress)"
            ><v-icon icon="mdi-email" />
            <v-tooltip
              activator="parent"
              location="top"
            >Send mail to {{ compensationItem.ManagerEmailAddress }}
            </v-tooltip></v-btn>
            {{ compensationItem.ManagerName }}
          </v-col>

          <v-col class="font-weight-bold">Performance Reviews In Cycle</v-col>
          <v-col>{{ compensationItem.PerformanceReviewsInCycle }}</v-col>
        </v-row>
        <v-row class="ml-4 mt-n4">
          <v-col class="font-weight-bold">Staff Compensation Type</v-col>
          <v-col>{{ compensationItem.UserCompensationTypeName }}</v-col>

          <v-col class="font-weight-bold">Average Performance Rating</v-col>
          <v-col>{{ compensationItem.CalculatedTotalScore ? compensationItem.CalculatedTotalScore + "%": "" }}</v-col>
        </v-row>
        <v-row class="ml-4 mt-n4">
          <v-col class="font-weight-bold">Length of Service (Months)</v-col>
          <v-col>{{ compensationItem.CurrentLengthOfService }}</v-col>

          <v-col class="font-weight-bold">Last Performance Rating</v-col>
          <v-col>{{ compensationItem.LastTotalScore ? compensationItem.LastTotalScore + "%": "" }}</v-col>
        </v-row>
        <v-row class="ml-4 mt-n4">
          <v-col class="font-weight-bold">Country</v-col>
          <v-col>{{ compensationItem.CountryName + " (" + compensationItem.CountryCode + ")" }}</v-col>

          <v-col class="font-weight-bold">Base Inflation Rate</v-col>
          <v-col v-if="compensationItem.CompensationTypeID === 0">{{ compensationItem.CountryBaseInflationRate ? compensationItem.CountryBaseInflationRate + "%" : "" }}</v-col>
          <v-col v-else>N/A</v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-alert
      v-if="compensationItem.LastRemCoIsFlagged && (compensationItem.UserID != userID)"
      class="mt-2"
      rounded="xl"
      density="compact"
      type="warning"
      title="A Performance Review within the Cycle was flagged"
      text=""
    ></v-alert>
    <v-alert
      v-if="compensationItem.NonEligibilityReason"
      class="mt-2"
      rounded="xl"
      density="compact"
      type="error"
      :title="`Not Eligible: ${compensationItem.NonEligibilityReason}`"
    ></v-alert>
    <v-card
      v-if="compensationItem.CompensationTypeID === 0 && (compensationItem.UserID != userID)"
      rounded="xl"
      elevation="4"
      class="mt-4 mb-4 pa-6">
      <v-card-title
        class="text-h4 text-primary">
        <v-row>
          <v-col>System Generated</v-col>
          <v-col>
            <div class="d-flex justify-end" v-if="compensationItem.CompensationTypeID == 0">{{ compensationItem.CalculatedRemuneration ? compensationItem.CalculatedRemuneration.toLocaleString(getLocaleFromCurrency(compensationItem.CurrencyCode), {style: 'currency', currency: compensationItem.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2}) + " (" + compensationItem.CalculatedInflationRate + "% Annual Increase)": "" }}</div>
            <div class="d-flex justify-end" v-else>{{ compensationItem.CalculatedRemuneration ? compensationItem.CalculatedRemuneration.toLocaleString(getLocaleFromCurrency(compensationItem.CurrencyCode), {style: 'currency', currency: compensationItem.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2}): "N/A" }}</div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="text-body-1">
        <v-row>
          <v-col class="font-weight-bold">Last Performance Review Comments</v-col>
        </v-row>
        <v-row class="mt-n4">
          <v-col>{{ compensationItem.LastManagerComments }}</v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      v-for="item in compensationItemManagerComments"
      rounded="xl"
      elevation="4"
      class="mt-4 mb-4 pa-6">
      <v-card-title
        class="text-h4 text-primary">
        <v-row>
          <v-col>{{item.ManagerName}} Proposed</v-col>
          <v-col>
            <div class="d-flex justify-end" v-if="compensationItem.CompensationTypeID == 0">{{ item.ManagerRemuneration ? item.ManagerRemuneration.toLocaleString(getLocaleFromCurrency(compensationItem.CurrencyCode), {style: 'currency', currency: compensationItem.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2}) + " (" + item.ManagerInflationRate + "%)": "" }}</div>
            <div class="d-flex justify-end" v-else>{{ item.ManagerRemuneration ? item.ManagerRemuneration.toLocaleString(getLocaleFromCurrency(compensationItem.CurrencyCode), {style: 'currency', currency: compensationItem.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2}): "N/A" }}</div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="text-body-1">
        <v-row>
          <v-col class="font-weight-bold">Comments</v-col>
        </v-row>
        <v-row>
          <v-col>{{item.ManagerComments}}</v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
    v-if="compensationItem.RemunerationIsFinal"
      rounded="xl"
      elevation="4"
      class="mt-4 mb-4 pa-6">
      <v-card-title
        class="text-h4 text-primary">
        <v-row>
          <v-col>Final</v-col>
          <v-col>
            <div class="d-flex justify-end" v-if="compensationItem.CompensationTypeID == 0">{{ compensationItem.FinalRemuneration ? compensationItem.FinalRemuneration.toLocaleString(getLocaleFromCurrency(compensationItem.CurrencyCode), {style: 'currency', currency: compensationItem.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2}) + " (" + compensationItem.FinalInflationRate + "%)": "" }}</div>
            <div class="d-flex justify-end" v-else>{{ compensationItem.FinalRemuneration ? compensationItem.FinalRemuneration.toLocaleString(getLocaleFromCurrency(compensationItem.CurrencyCode), {style: 'currency', currency: compensationItem.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2}): "" }}</div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="text-body-1">
        <v-row>
          <v-col class="font-weight-bold">Remuneration Committee Comments</v-col>
        </v-row>
        <v-row class="mt-n4">
          <v-col>{{ compensationItem.RemCoComments }}</v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      class="bg-primary mt-4 mb-4 pa-6"
      rounded="xl"
      elevation="4"
      style="overflow-y: auto"
    >
      <v-card-title class="text-h4">
        <v-row>
          <v-col>Current Annual Remuneration</v-col>
          <v-col>
              <div class="d-flex justify-end">{{ compensationItem.CurrentRemuneration ? compensationItem.CurrentRemuneration.toLocaleString(getLocaleFromCurrency(compensationItem.CurrencyCode), {style: 'currency', currency: compensationItem.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2}): "N/A" }}</div>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-card
      v-if="allowOverride"
      rounded="xl"
      elevation="4"
      class="mt-4 mb-4 pa-6"
    >
      <v-card-title
        class="text-h4 text-primary">
        <v-row>
          <v-col v-if="isManagerReview">Manager Comments</v-col>
          <v-col v-if="isRemCoReview">Remuneration Committee Comments</v-col>
        </v-row>
      </v-card-title>        
      <v-card-text>
        <v-form v-model="isOverrideValid">
          <v-row>
            <v-col>
              <v-text-field
                v-if="compensationItem.CompensationTypeID === 0"
                persistent-placeholder
                variant="solo"
                :rules="rulesIsNumber"
                v-model="overrideCompensationManagerInflationRate"
                min="0"
                :label="`Proposed ${compensationItem.CompensationTypeName} %`"
                type="number"
              >
              </v-text-field>
              <v-text-field
                v-if="compensationItem.CompensationTypeID != 0"
                persistent-placeholder
                variant="solo"
                type="number"
                :label="`Proposed ${compensationItem.CompensationTypeName}`"
                v-model="overrideCompensationManagerRemuneration"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-if="compensationItem.CompensationTypeID === 0"
                persistent-placeholder
                variant="solo"
                readonly
                :label="`Proposed ${compensationItem.CompensationTypeName}`"
                v-model="formattedOverrideCompensationManagerRemuneration"
                persistent-hint
                hint="This only is an indication of final remuneration">
              </v-text-field>
            </v-col>
            <v-col></v-col>
            <v-col></v-col>
          </v-row>
          <v-row>
            <v-col><v-textarea
              clearable
              persistent-placeholder
              variant="solo"
              v-model="overrideCompensationManagerComments"
              label="Comments"
              prepend-inner-icon="mdi-comment"
              counter
              maxlength="1000"
            >
            </v-textarea></v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card
      rounded="xl"
      elevation="4"
      class="mt-4 mb-4 pa-6"
    >
      <v-card-actions>
        <v-btn
          variant="tonal"
          color="primary"
          @click="goBack()"
        >Back</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="allowOverride"
          color="success"
          variant="tonal"
          :disabled="!isOverrideValid"
          @click="loadOverride()">{{ isRemCoReview ? "Approve": "Submit" }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>

  <v-dialog v-model="dialogOverride" max-width="750px">
      <template v-slot:activator="{ props }">
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Approve {{ compensationItem.Name }} {{ compensationItem.CompensationYear }} {{ getPerformanceCyclePeriod(compensationItem.CompensationPeriod) }} {{ compensationItem.CompensationTypeName }}</span>
        </v-card-title>
        <v-card-text>
          <div v-if="compensationItem.CompensationTypeID == 0">
          Are you sure you want to approve an increase of {{ formattedOverrideCompensationManagerRemuneration }} ({{ overrideCompensationManagerInflationRate }}%)?
          </div>
          <div v-if="compensationItem.CompensationTypeID != 0">
          Are you sure you want to approve remuneration of {{ formattedOverrideCompensationManagerRemuneration }}?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" variant="text" @click="saveOverride()">Yes</v-btn>
          <v-btn color="error" variant="text" @click="closeOverride()">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import authService from "@/services/authService"
import utils from "@/services/utils"

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const route = useRoute()
const router = useRouter()

const userID = localStorage.getItem("user_id")
const compensationID = route.params.id

const allowOverride = ref(false)
const isRemCoReview = ref(false)
const isManagerReview = ref(false)
const dialogOverride = ref(false)
const loading = ref(true)
const search = ref("")
const selected = ref([])
const showFilters = ref(false)
const zeroRemuneration = ref(0)

const compensationItem = ref({})
const compensationItemManagerComments = ref([])

const rulesIsRequired = [v => !!v || "Value is required"]
const rulesIsNumber = [v => (v >=0) || "Value is required"]

const isOverrideValid = ref(false)
var overrideCompensationCompensationID = ref(null)
var overrideCompensationManagerInflationRate = ref(null)
var overrideCompensationManagerRemuneration = ref(null)
var overrideCompensationManagerComments = ref("")
var formattedOverrideCompensationManagerRemuneration = ref(0)

function formatOverrideCompensationManagerRemuneration() {
  if (overrideCompensationManagerRemuneration.value) {
    const locale = getLocaleFromCurrency(compensationItem.value.CurrencyCode)
    formattedOverrideCompensationManagerRemuneration.value = Number(overrideCompensationManagerRemuneration.value || null).toLocaleString(locale, {
        style: 'currency',
        currency: (compensationItem.value.CurrencyCode || 'USD'),
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    })
  }
  else {
    formattedOverrideCompensationManagerRemuneration.value = null
  }
}

watch(overrideCompensationManagerRemuneration, (newValue) => {
  formatOverrideCompensationManagerRemuneration()
})

watch(overrideCompensationManagerInflationRate, (newValue) => {
  if (compensationItem.value.CompensationTypeID === 0) {
    overrideCompensationManagerRemuneration.value = (compensationItem.value.CurrentRemuneration) + (compensationItem.value.CurrentRemuneration * newValue / 100)    
  }
})

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

function sendMail(emailAddress) {
  window.open(`mailto:${emailAddress}`)
  updateResultMessage("success|Sending Mail|" + emailAddress)
}

function formatDatePeriod(dateString) {
  return utils.formatDatePeriod(dateString)
}

function getLocaleFromCurrency(key) {
  const rtn = utils.LOCALENAMEFROMCURRENCY[key] || undefined
  return rtn
}

function getPerformanceCyclePeriod(key) {
  try {
    return utils.PERFORMANCECYCLEPERIODS[key-1].title
  }
  catch {
    return ""
  }
}

function goBack() {
/*
  router.push({
    name: "compensation-list",
    params: {
      type: 'current'
    }
  })
*/

  router.go(-1)
}

async function getCompensation() {
  await utils.httpRequest(`/Compensation/${compensationID}`, utils.REQUEST.GET, (data) => {
    compensationItem.value = data
    if (data.Error) {
      updateResultMessage("error|Cannot fetch Compensation|" + data.Error.Message)
      router.push({
        name: "compensation-list",
        params: {
          type: 'current'
        }
      })
    }
    emit("updateBreadcrumb", ["Compensation Module", compensationItem.value.Name, compensationItem.value.CompensationYear.toString(), compensationItem.value.CompensationTypeName])
  })

  if (!compensationItem.RemunerationIsFinal) {
    overrideCompensationManagerInflationRate.value = compensationItem.value.ProposedInflationRate
    overrideCompensationManagerRemuneration.value = compensationItem.value.ProposedRemuneration
    formatOverrideCompensationManagerRemuneration()
  }

  isManagerReview.value = (!compensationItem.value.RemunerationIsFinal && (compensationItem.value.AssignedManagerID == userID))
  isRemCoReview.value = (!isManagerReview.value) && (!compensationItem.value.RemunerationIsFinal) && (!compensationItem.value.AssignedManagerID || (await authService.isRemCo()))
  allowOverride.value = isManagerReview.value || isRemCoReview.value

  getCompensationComments()
}

async function getCompensationComments() {
  await utils.httpRequest(`/Compensation/${compensationID}/Comments`, utils.REQUEST.GET, (data) => {
    if (!data.Error) {
      compensationItemManagerComments.value = data
    }
  })
}

async function saveOverride() {
  let uri
  let payload = {
      ManagerInflationRate: Number(overrideCompensationManagerInflationRate.value),
      ManagerRemuneration: overrideCompensationManagerRemuneration.value,
      ManagerComments: overrideCompensationManagerComments.value
    }

  try {
    if (isManagerReview.value) {
        uri = "/Compensation/" + compensationID + "/Comments/" + compensationItem.value.CompensationManagerCommentID
    
    }
    else if (isRemCoReview.value) {
      uri = "/RemCo/" + compensationID + "/Override"
    }
    else {
      throw new Error("Cannot determine Compensation Comment Type")
    }

    await utils.httpRequest(uri, utils.REQUEST.POST, (data) => {
        if (!data.Error) {
          updateResultMessage("success|Done|Successfully Approved Remuneration")
          if (isRemCoReview.value) {
            router.push({
              name: "compensation-list",
              params: {
                type: 'remco'
              }
            })
          }
          else {
            router.push({
              name: "compensation-list",
              params: {
                type: 'current'
              }
            })
          }
        } else {
          throw new Error(data.Error.Message)
        }
      }, payload
    )
  } catch (e) {
    updateResultMessage("error|Data Issue|" + e.message)
  }
  closeOverride()
}

function bulkApprove() {
  dialogBulkApprove.value = true
}

function loadOverride() {
  dialogOverride.value = true
}

function closeOverride () {
  dialogOverride.value = false
}

onMounted(async () => {
  emit("updateBreadcrumb", ["Compensation Module"])
  emit("updateHeader", "Compensation")
  getCompensation()
})
</script>
