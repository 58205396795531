<template>
  <Bar id="barchart" :data="chartData" :options="chartOptions" />
</template>
<script setup>
import { onBeforeMount } from "vue"
import { Bar, Line } from "vue-chartjs"
import { Chart as ChartJS, Colors, Title, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement } from "chart.js"
import { useTheme } from "vuetify"
import utils from "@/services/utils"

const theme = useTheme()

ChartJS.register(Title, Tooltip, Legend, Colors, CategoryScale, LinearScale, PointElement, LineElement, BarElement)
ChartJS.defaults.font.size = 14

const props = defineProps({
  data: Object,
})

var chartOptions = {
  barValueSpacing: 20,
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
    },
    tooltip: {
      enabled: true,
      cornerRadius: 12,
      caretSize: 0,
    }
  },
  scales: {
    x: {
      stacked: false,
    },
    y: {
      steps: 1000,
      stepValue: 5,
      position: 'left',
      min: 0,
      //ticks: {
      //  callback: function(value, index, values) {
      //    return value.toLocaleString("en-US", {style:"currency", currency:"USD"})
      //  }
      //}
    },
  },
}

var chartData = []
function renderGraph() {
  let scoredPRs = props.data.reduce((acc, item) => {
    let d = item.Name + " (" + item.CurrencyCode + ")"
    if (!acc[d]) {
      acc[d] = []
    }
    acc[d].push(item)
    return acc
  }, {})

  const datasets = []
  for (let s in scoredPRs) {
    let remuneration = scoredPRs[s][0].FinalRemuneration ? scoredPRs[s][0].FinalRemuneration : scoredPRs[s][0].ProposedRemuneration
    if (remuneration > 0) {
      scoredPRs[s].sort(function(a, b) {
        var x = (a["CompensationCycleID"])
        var y = (b["CompensationCycleID"])
        return ((x < y) ? -1 : ((x < y) ? 1 : 0))
      })

      datasets.push({
        label: s,
        data: scoredPRs[s].map(item => ({
          x: item.CompensationYear + " - " + getPerformanceCyclePeriod(item.CompensationPeriod) + " (" + item.CompensationTypeName + ")",
          y: item.FinalRemuneration ? item.FinalRemuneration : item.ProposedRemuneration
        }))
      })
    }
  }

  return { datasets }
}

function getPerformanceCyclePeriod(key) {
  try {
    return utils.PERFORMANCECYCLEPERIODS[key-1].title
  }
  catch {
    return ""
  }
}

onBeforeMount(() => {
  chartData = renderGraph()
})
</script>
