<template><div>
<v-row>
  <v-col>
    <v-card
      class="mt-0 mb-6 ml-2 mr-2 mt-4 d-flex flex-column"
      rounded="xl"
      elevation="4"
      style="overflow-y: auto"
      min-height="620"
    >
      <v-card-text>
        <v-expansion-panels
          rounded="xl">
          <v-expansion-panel
            static
            color="secondary">
            <v-expansion-panel-title>
              <v-row>
                <v-col class="d-flex justify-end">
                  Filters and Options
                </v-col>
              </v-row>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              class="mb-n4">
              <v-row>
                <v-col></v-col>
                <v-col class="d-flex justify-end">
                  <xlsxexportbutton class="mr-2" :rawJSON="filteredItems" :updateMessage="updateResultMessage"></xlsxexportbutton>
                  <v-btn
                    variant="tonal"
                    color="primary"
                    icon=""
                    @click="addNew()"
                  >
                    <v-icon icon="mdi-plus-thick" />
                    <v-tooltip
                      activator="parent"
                      location="top"
                    >Add New
                    </v-tooltip>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="mb-6 pb-10"></div>
                </v-col>
                <v-col justify="end">
                  <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    label="Search"
                    density="compact"
                    single-line
                    hide-details
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-data-table
          density="compact"
          :headers="lookupHeaders"
          :items="filteredItems"
          :item-value="lookupPrimaryKey"
          items-per-page="-1"
          hide-default-footer="true">
          <template v-slot:item.PerformanceMetricID="{ item }">
            {{ item.PerformanceMetricID }}
          </template>
          <template v-slot:item.PerformanceMetricName="{ item }">
            <pre>{{ item.PerformanceMetricName }}</pre>
          </template>
          <template v-slot:item.actions="{ item }">
            <div>
              <v-btn
                color="primary"
                icon=""
                variant="plain"
                density="compact"
                @click="showEdit(item)"
              ><v-icon size="sm" icon="mdi-eye" />
                <v-tooltip
                  activator="parent"
                  location="top"
                >View {{  item.Name }}
                </v-tooltip>
              </v-btn>
              <v-btn
                color="error"
                icon=""
                variant="plain"
                density="compact"
                @click="showDelete(item.PerformanceMetricID)"
              ><v-icon size="sm" icon="mdi-delete" />
                <v-tooltip
                  activator="parent"
                  location="top"
                >Delete {{  item.Name }}
                </v-tooltip>
              </v-btn>
            </div>
          </template>
          <template v-slot:bottom> </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-col>
</v-row>
<v-row><v-col></v-col></v-row>

<v-dialog v-model="showDialogEditItem" width="750px">
  <v-form v-model="isEditValid">
    <v-card>
      <v-card-title>Edit {{ lookupNameReadable }}</v-card-title>
      <v-card-text>
        <v-row><v-col></v-col></v-row>
        <v-row>
          <v-col class="pa-0 ma-1" cols="2"><v-text-field
            density="compact"
            persistent-placeholder
            variant="plain"
            readonly
            type="number"
            label="ID"
            v-model="editItem.PerformanceMetricID"
          /></v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0 ma-1"><v-textarea
            density="compact"
            persistent-placeholder
            variant="underlined"
            label="Name"
            :rules="rulesIsRequired"
            v-model="editItem.PerformanceMetricName"
          /></v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0 ma-1">
            <div class="text-caption text-grey">Weight</div>
            <v-slider
              thumb-label
              color="secondary"
              :min="1"
              :max="100"
              :step="1"
              v-model="editItem.PerformanceMetricWeight"
            >
              <template v-slot:append>
                <v-text-field
                  hide-details
                  single-line
                  type="number"
                  variant="outlined"
                  density="compact"
                  style="width: 80px"
                  v-model="editItem.PerformanceMetricWeight"
                />
              </template>
            </v-slider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :disabled="!isEditValid"
          @click="saveEditItem()"
        >Save</v-btn>
        <v-btn
          color="error"
          @click="cancelEditItem()"
        >Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</v-dialog>

<v-dialog v-model="showDialogDeleteItem" max-width="430px">
    <v-card>
      <v-card-title>Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            @click="deleteItem(deleteItemIndex)"
          >OK</v-btn>
          <v-btn
            color="error"
            @click="cancelDeleteItem()"
          >Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</div></template>
<script setup>
import { ref, watch, onMounted, computed } from 'vue'
import authService from '@/services/authService'
import utils from '@/services/utils'
import { useDate } from 'vuetify'
import { useRouter } from 'vue-router'

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const props = defineProps({
  updateMessage: Function
})
var search = ref(null)
var filteredItems = ref([])
var selectedIsActive = ref(true)

const lookupPrimaryKey = "PerformanceMetricID"
const lookupName = "PerformanceMetrics"
const lookupNameReadable = "Performance Metrics"
const lookupHeaders = [
  { title: "ID", key: "PerformanceMetricID", value: "PerformanceMetricID", width: "90px"},
  { title: "Name", key: "PerformanceMetricName", value: "PerformanceMetricName" },
  { title: "Weight (%)", key: "PerformanceMetricWeight", value: "PerformanceMetricWeight", width: "140px" },
  { title: "Actions", key: "actions", width: "90px", sortable: false },
]

var editItem = ref({})
var editItemIndex = ref(null)
var deleteItemIndex = ref(null)
var isEditValid = ref(false)

var showDialogEditItem = ref(false)
var showDialogDeleteItem = ref(false)

var lookupItems = ref([])
const isLoading = ref(false)

const rulesIsRequired = [v => !!v || "Value is required"]

function updateResultMessage(message) {
  props.updateMessage(message)
}

async function getLookups() {
}

watch(selectedIsActive, (newValue, oldValue) => {
  resetFilter()
})

watch(search, (newValue, oldValue) => {
  resetFilter()
})

function resetFilter() {
  isLoading.value = true
  filteredItems.value = lookupItems.value.filter(item => {
    let textSearch = utils.jsonObjectHasText(item, search.value)
    return textSearch
  })
  isLoading.value = false
}

async function getData() {
  await utils.httpRequest(`/${lookupName}/`, utils.REQUEST.GET, (data) => {
    lookupItems.value = data
  })
  resetFilter()
}

async function showEdit(item) {
  editItemIndex.value = lookupItems.value.indexOf(item) 
  editItem.value = Object.assign({}, item)
  showDialogEditItem.value = true
}

async function showDelete(dataID) {
  deleteItemIndex.value = dataID
  showDialogDeleteItem.value = true
}

function addNew() {
  editItem.value = {}
  editItem.value.PerformanceMetricID = null
  showDialogEditItem.value = true
}

async function saveData() {
  let payload = lookupItems.value
  let uri = `/${lookupName}`
  try {
    await utils.httpRequest(uri, utils.REQUEST.POST, (data) => {
    if (!data.Error) {
          updateResultMessage("success|Saved|Saved Successfully", )
        } else {
          throw new Error(data.Error.Message)
        }
    },
    payload)
    await getData()
  } catch (e) {
      updateResultMessage("error|Save error|" + e.message)
  }
}

async function saveEditItem() {
  Object.assign(lookupItems.value[editItemIndex.value], editItem.value)
  saveData()
  showDialogEditItem.value = false
}

function deleteItem() {
  lookupItems.value.splice(deleteItemIndex.value, 1)
  saveData()
  showDialogDeleteItem.value = false
}

function cancelEditItem() {
  showDialogEditItem.value = false
}

function cancelDeleteItem() {
  showDialogDeleteItem.value = false
}

onMounted(async () => {
  await getData()
  await getLookups()
})

</script>
