<template>
  <div class="ml-3 mr-6">
    <v-snackbar
      v-model="invalidWeight"
      color="error"
      timeout="-1"
    >The total weight of this review should be less than or equal to 100%. Currently it is {{ totalWeight }}%
    </v-snackbar>

    <performancereviewuser
      :Name="performanceReview.Name"
      :Period="formatDatePeriod(performanceReview.ManagerReviewStartDate) + ' - ' + formatDatePeriod(performanceReview.ManagerReviewEndDate)"
      :CurrentPhaseID=performanceReview.PerformancePhaseID
      :PerformancePhases="PerformancePhases"
    />

    <v-card
      title="Performance Metrics"
      class="pa-3 mt-4 bg-secondary"
      elevation="4"
      rounded="xl"
      prepend-icon="mdi-medal"
    >
      <v-card-subtitle
        v-html="'Your Performance Metrics should be SMART (Specific,Measurable,Accurate,Relevant and Timely), short and to the point.<br />Your Performance Metrics equate to <strong>' + PerformanceMetricsTotalWeight + '%</strong> of your total score'"
      ></v-card-subtitle>
    </v-card>

    <v-sheet
      v-for="PerformanceReviewMetric in performanceReviewMetrics"
      class="mt-4 pa-6"
      rounded="xl"
      elevation="4"
    >
      <v-row>
        <v-col cols="2"></v-col>
        <v-col>
          <v-textarea
            v-model="PerformanceReviewMetric.MetricDescription"
            density="compact"
            rounded="xl"
            variant="solo"
            label="Description"
            clearable
            counter
            maxlength="2000"
            @input="updatePerformanceReviewMetric(PerformanceReviewMetric, 'description')"
          />
        </v-col>
        <v-col
          cols="2"
          align="right">
          <v-btn
            size="x-large"
            color="error"
            icon=""
            variant="elevated"
            density="compact"
            @click="deletePerformanceReviewMetric(PerformanceReviewMetric)"
          ><v-icon icon="mdi-close" size="x-large" />
           <v-tooltip
             activator="parent"
             location="top"
           >Delete Performance Metric
           </v-tooltip></v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2"></v-col>
        <v-col>
          <div class="text-caption text-grey">Weight</div>
          <v-slider
            v-model="PerformanceReviewMetric.PerformanceMetricWeight"
            density="compact"
            :min="1"
            :max="100"
            :step="1"
            thumb-label
            color="secondary"
            type="number"
            @update:model-value="updatePerformanceReviewMetric(PerformanceReviewMetric, 'weight')"
          >
            <template v-slot:append>
              <v-text-field
                v-model="PerformanceReviewMetric.PerformanceMetricWeight"
                variant="solo"
                type="number"
                style="width: 80px"
                hide-details
                @update:model-value="updatePerformanceReviewMetric(PerformanceReviewMetric, 'weight')"
              />
            </template>
          </v-slider>
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>
  </v-sheet>
  <v-card
    class="mt-4 mb-4 pl-6 pr-6"
    elevation="4"
    rounded="xl"
  >
    <v-card-actions>
      <v-btn
        variant="tonal"
        color="primary"
        @click="goBack()"
      >Back</v-btn>
      <v-btn
        variant="tonal"
        color="primary"
        @click="addPerformanceReviewMetric()"
      >Add New Metric
      </v-btn>
      <v-btn
        v-if="validWeight && !saved"
        variant="tonal"
        color="primary"
        @click="save()"
      >Save</v-btn>
      <v-dialog v-model="dialog" :persistent="true" width="auto" v-if="validWeight && saved && PerformanceCycle.PerformancePhaseID >= 1">
        <template v-slot:activator="{ props }">
          <v-btn
            variant="tonal"
            color="primary"
            v-bind="props">Submit</v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5">Continue with scoring?</v-card-title>
          <v-card-text>
            By confirming this dialog you are confirming that everything entered is correct
            and you will not be able to change anything after the submission.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" variant="text" @click="releasePerformanceReviewMetrics()">Yes</v-btn>
            <v-btn color="error" variant="text" @click="dialog=false">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
  </div>

  <v-dialog v-model="showErrorDialog" width="auto" :persistent="true">
    <v-card>
      <v-card-text>
        {{ errorDialogText }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          block
          @click="cancelErrorDialog()"
          >Close Dialog</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <progressdialog v-model="releaseInProgress" message="Releasing Performance Review, please hold..."></progressdialog>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import authService from "@/services/authService"
import utils from "@/services/utils"

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const route = useRoute()
const router = useRouter()

const performanceReviewID = ref(route.params.id)
var performanceReview = ref({})
var performanceReviewMetrics = ref([])
const PerformanceCycle = ref({})
const PerformancePhases = ref([])
const PerformanceMetrics = ref([])

const dialog = ref(false)
const showErrorDialog = ref(false)
const releaseInProgress = ref(false)
const errorDialogText = ref("")
const validWeight = ref(false)
const totalWeight = ref(0)
const saved = ref(false)
const PerformanceMetricsTotalWeight = ref(0)

const invalidWeight = computed(() => !validWeight.value)

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

function validateWeight() {
  totalWeight.value = performanceReviewMetrics.value.reduce(
    (acc, curr) => acc + parseFloat(curr.PerformanceMetricWeight),
    0
  )
  if (typeof totalWeight.value === "number" && totalWeight.value === 100) {
    validWeight.value = true
  } else {
    validWeight.value = false
  }
  return validWeight.value
}

function goBack() {
  router.push({
    name: "performance-review-list",
    params: { type: "current" },
  })
}

function addPerformanceReviewMetric() {
  let newPerformanceReviewMetric = {
    PerformanceMetricWeight: 0,
    MetricDescription: "Default Goal Description " + (performanceReviewMetrics.value.length+1),
    PerformanceReviewMetricScoreID: '-' + (performanceReviewMetrics.value.length+1)
  }
  performanceReviewMetrics.value.push(newPerformanceReviewMetric)
  saved.value = false
  updateResultMessage("success|Done|Added new Performance Metric")
  validateWeight()
}

function deletePerformanceReviewMetric(PerformanceReviewMetric) {
  // Find the object with the specified PerformanceReviewMetricScoreID
  const foundObjectIndex = performanceReviewMetrics.value.findIndex(
    (item) => item.PerformanceReviewMetricScoreID === PerformanceReviewMetric.PerformanceReviewMetricScoreID
  )
  performanceReviewMetrics.value.splice(foundObjectIndex, 1)
  validateWeight()
  saved.value = false
}

function updatePerformanceReviewMetric(PerformanceReviewMetric, fieldName) {
  // Find the object with the specified PerformanceReviewMetricScoreID
  const foundObjectIndex = performanceReviewMetrics.value.findIndex(
    (item) => item.PerformanceReviewMetricScoreID === PerformanceReviewMetric.PerformanceReviewMetricScoreID
  )
  if (foundObjectIndex !== -1) {
    // Update the values of the found object
    if (fieldName === "weight") {
      performanceReviewMetrics.value[foundObjectIndex].PerformanceMetricWeight = PerformanceReviewMetric.PerformanceMetricWeight
    }
    if (fieldName === "description") {
      performanceReviewMetrics.value[foundObjectIndex].MetricDescription = PerformanceReviewMetric.MetricDescription
      saved.value = false
    }
    validateWeight()
  }
}

async function save() {
  try {
    dialog.value = false
    const postData = JSON.stringify({
        UserID: route.query.userid,
        PerformanceReviewMetricScores: performanceReviewMetrics.value,
      })
    let uri = `/PerformanceReviews/`
    if (performanceReviewID.value != "new") {
      uri = uri + performanceReviewID.value
    } else {
    }
    await utils.httpRequest(uri, utils.REQUEST.POST, (data) => {
        if (!data.Error) {
          saved.value = true
          if (performanceReviewID.value === "new") {
            router.push({
              name: "performance-review-metric",
              params: { id: data.PerformanceReviewID },
            })
          } else {
            performanceReview.value = data
          }
          updateResultMessage("success|Done|Successfully Saved Performance Review")
        } else {
            throw new Error(data.Error.Message)
          }
      },
      postData
    )
  } catch (e) {
    updateResultMessage("error|Data Issue|" + e.message
    )
  }
}

async function releasePerformanceReviewMetrics() {
  releaseInProgress.value = true
  try {
    await utils.httpRequest(`/PerformanceReviews/${performanceReviewID.value}/Release/`, utils.REQUEST.POST, (data) => {
        if (!data.Error) {
          router.push({
            name: "performance-review-detail",
            params: performanceReviewID.value,
          })
        } else {
          throw new Error(data.Error.Message)
        }
      }
    )
  } catch (err) {
    showErrorDialog.value = true
    errorDialogText.value = err.message
  }
  releaseInProgress.value = false
}

async function getPerformancePhases() {
  await utils.httpRequest(`/PerformancePhases`, utils.REQUEST.GET, (data) => {
      PerformancePhases.value = data
    }
  )
}

async function getPerformanceReview() {
  await utils.httpRequest(`/PerformanceReviews/${performanceReviewID.value}`, utils.REQUEST.GET, (data) => {
      emit("updateHeader", `Performance Reviews - ${data.Name}`)
      emit("updateBreadcrumb", ["Performance Review", data.Name])
      if (data.PerformancePhaseID > 1 || (data.ManagerUserID != localStorage.getItem("user_id") && data.IsImpersonation === false)) {
        router.push({
          name: "performance-review-detail",
          params: route.params.id,
        })
      }
      performanceReview.value = data
    }
  )
}

async function getUserDetails(id) {
  await utils.httpRequest(`/Users/${id}/Basic`, utils.REQUEST.GET, (data) => {
      performanceReview.value.Name = data.Name
      performanceReview.value.PerformancePhaseID = 0
    }
  )
}

async function getPerformanceCycle(id) {
  await utils.httpRequest(`/PerformanceCycles/${id}`, utils.REQUEST.GET, (data) => {
      PerformanceCycle.value = data
      performanceReview.value.ManagerReviewStartDate = data.ManagerReviewStartDate
      performanceReview.value.ManagerReviewEndDate = data.ManagerReviewEndDate
    }
  )
}

async function getPerformanceMetrics() {
  await utils.httpRequest(`/PerformanceMetrics`, utils.REQUEST.GET, (data) => {
      PerformanceMetrics.value = data
    })
}

function cancelErrorDialog() {
  showErrorDialog.value = false
  errorDialogText.value = ''
}

function formatDatePeriod(dateString) {
  return utils.formatDatePeriod(dateString)
}

async function getPerformanceReviewsMetricsByPerformanceReviewID() {
  await utils.httpRequest(`/PerformanceReviews/${performanceReviewID.value}/Metrics/`, utils.REQUEST.GET, (data) => {
    performanceReviewMetrics.value = data
    validateWeight()
    })
}

async function getPerformanceMetricsTotalWeight() {
  await utils.httpRequest(`/PerformanceValueFactors/`, utils.REQUEST.GET, (data) => {
      PerformanceMetricsTotalWeight.value = data.filter(obj => obj.PerformanceValueFactorID === 0).map(obj => obj.PerformanceValueFactorWeight)
    })
}

onMounted(async () => {
  getPerformanceMetrics()
  getPerformancePhases()
  getPerformanceMetricsTotalWeight()
  if (performanceReviewID.value != "new") {
    await getPerformanceReview()
    await getPerformanceCycle(performanceReview.value.PerformanceCycleID)
    await getPerformanceReviewsMetricsByPerformanceReviewID()
  } else {
    await getUserDetails(route.query.userid)
    await getPerformanceCycle(route.query.performancecycleid)
  }
})
</script>
