<template>
  <div class="ml-3 mr-6">
    <performancereviewuser
      Name="Current Performance Cycle Phase"
      :CurrentPhaseID="PerformanceCycle.PerformancePhaseID"
      :PerformancePhases="PerformancePhases"
    />
 
    <v-card
      rounded="xl"
      elevation="4"
      class="mt-4 mb-4 pa-6"
    >
      <v-card-text>
        <v-row>
          <v-col><div class="d-flex justify-end"><v-switch
              variant="solo"
              v-model="PerformanceCycle.IsActive"
              label="Active?"
              color="green-darken-3"
              hide-details
              readonly
            >
            </v-switch></div></v-col>
        </v-row>
        <v-row>
          <v-col><v-select
            density="compact"
            variant="solo"
            label="Period"
            v-model="selectedPeriod"
            :items="PERFORMANCECYCLEPERIODS"
            :readonly="!PerformanceCycle.IsActive"
          ></v-select></v-col>
          <v-col><v-text-field
            density="compact"
            variant="solo"
            v-model="PerformanceCycle.Year"
            :rules="numericalRules"
            label="Year"
            type="number"
            :readonly="!PerformanceCycle.IsActive"
          ></v-text-field></v-col>  
          <v-col></v-col>
          <v-col><datepicker
            density="compact"
            color="secondary"
            variant="solo"
            label="Start Date"
            v-model="PerformanceCycle.ManagerReviewStartDate"
            :readonly="!PerformanceCycle.IsActive"
            ></datepicker></v-col>
          <v-col><datepicker
            density="compact"
            color="secondary"
            variant="solo"
            label="End Date"
            v-model="PerformanceCycle.ManagerReviewEndDate"
            :readonly="!PerformanceCycle.IsActive"
            ></datepicker></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="ma-1"
          variant="tonal"
          @click="
            router.push({
              name: 'performance-cycle',
            })"
        >Back</v-btn>

        <v-btn
          v-if="PerformanceCycle.IsActive"
          class="ma-1"
          variant="tonal"
          :disabled="!valid"
          @click="savePerformanceCycle()"
        >Save</v-btn>
            
        <v-dialog v-model="dialogRelease" :persistent="true" width="auto" v-if="[0, 1].includes(PerformanceCycle.PerformancePhaseID)">
          <template v-slot:activator="{ props }">
            <v-btn color="primary" v-bind="props" class="ma-1" variant="tonal"
            >Release
          </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">Release Performance Cycle</v-card-title>
            <v-card-text>
              Are you sure you want to release Performance Cycle #{{ PerformanceCycle.PerformanceCycleID }}?
              <v-spacer></v-spacer>
              By confirming this dialog you are confirming that all Performance Reviews can now be {{ (PerformanceCycle.PerformancePhaseID === 0 ? "Reviewed by Managers" : "Scored") }}.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" variant="text" @click="releasePerformanceCycle(PerformanceCycle.PerformancePhaseID + 1)">Yes</v-btn>
              <v-btn color="error" variant="text" @click="closeDialogRelease">No</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        
        <v-dialog v-model="dialogClose" :persistent="true" width="auto" v-if="PerformanceCycle.PerformancePhaseID === 2">
          <template v-slot:activator="{ props }">
            <v-btn color="primary" v-bind="props" class="ma-1" variant="tonal">Close</v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">Close Performance Cycle</v-card-title>
            <v-card-text>
                Are you sure you want to close this performance cycle #{{ PerformanceCycle.PerformanceCycleID }}?
                <v-spacer></v-spacer>
                By confirming this dialog you are confirming that all open Performance Reviews are complete and unfinished Reviews will without score.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" variant="text" @click="closePerformanceCycle()">Yes</v-btn>
              <v-btn color="error" variant="text" @click="closeDialogClose()">No</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="ma-1"
          variant="tonal"
          @click="routeToPerformanceReviewList()">Performance Reviews</v-btn>
      </v-card-actions>
    </v-card>
    <progressdialog v-model="completeInProgress" message="Completing Release, please hold..."></progressdialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import authService from "@/services/authService"
import utils from "@/services/utils"

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const route = useRoute()
const router = useRouter()

const valid = ref(true)
const performanceCycleID = route.params.id
const PerformanceCycle = ref({})
const PerformancePhases = ref([])
const PERFORMANCECYCLEPERIODS = ref(utils.PERFORMANCECYCLEPERIODS)
const selectedPeriod = ref(null)
const dialogRelease = ref(false)
const dialogClose = ref(false)
const completeInProgress = ref(false)

// Validation rules
const numericalRules = [
  (v) => !!v || "Numerical value is required",
  (v) => (!isNaN(parseFloat(v)) && isFinite(v)) || "must be a number",
]

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

function routeToPerformanceReviewList() {
  router.push({
    name: "performance-cycle-performance-reviews",
    params: { id: performanceCycleID },
  })
}

async function getPerformancePhases() {
  await utils.httpRequest(`/PerformancePhases`, utils.REQUEST.GET, (data) => {
      PerformancePhases.value = data
    }
  )
}

async function getPerformanceCycle() {
  await utils.httpRequest(`/PerformanceCycles/${performanceCycleID}`, utils.REQUEST.GET, (data) => {
      PerformanceCycle.value = data,
      selectedPeriod.value = PerformanceCycle.value.Period
    }
  )
}

async function savePerformanceCycle() {
  try {
    PerformanceCycle.value.Period = selectedPeriod.value
    let payload = PerformanceCycle.value
    let uri = `/PerformanceCycles/`
    if (performanceCycleID != "new") {
      uri = uri + performanceCycleID
    }
    await utils.httpRequest(uri, utils.REQUEST.POST, (data) => {
        if (!data.Error) {
          router.push({
            name: "performance-cycle-form",
            params: { id: data.PerformanceCycleID },
          })
          updateResultMessage("success|Done|Successfully Saved Performance Cycle")
        } else {
          throw new Error(data.Error.Message)
        }
      },
      payload
    )
    return
  } catch (e) {
    updateResultMessage("error|Data Issue|" + e.message)
  }
}

async function releasePerformanceCycle(performancePhaseID) {
  completeInProgress.value = true

  let payload = { PerformancePhaseID: performancePhaseID }
  try {
    await utils.httpRequest(`/PerformanceCycles/${performanceCycleID}/Release`, utils.REQUEST.POST, (data) => {
        if (!data.Error) {
          updateResultMessage("success|Success|Performance cycle #" + performanceCycleID + " released successfully")
          getPerformanceCycle()
        } else {
          throw new Error(data.Error.Message)
        }
      },
      payload
    )
  } catch (e) {
    updateResultMessage("error|Data Issue|" + e.message)
  }
  completeInProgress.value = false
  dialogRelease.value = false
}

async function closePerformanceCycle() {
  try {
    await utils.httpRequest(`/PerformanceCycles/${performanceCycleID}/Close`, utils.REQUEST.POST, (data) => {
        if (!data.Error) {
          updateResultMessage("success|Success|Performance cycle # " + performanceCycleID + " close successfully")
          getPerformanceCycle()
        } else {
          throw new Error(data.Error.Message)
        }
      }
    )
    dialogClose.value = false
  } catch (e) {
    updateResultMessage("error|Data Issue|" + e.message)
  }
}

function closeDialogRelease () {
  dialogRelease.value = false
}

function closeDialogClose () {
  dialogClose.value = false
}

onMounted(async () => {
  emit("updateBreadcrumb", ["Administration", "Performance Cycles", performanceCycleID])
  emit("updateHeader", "Performance Cycle")
  if (await authService.isPerformanceAdmin()) {
    getPerformancePhases()
    if (route.params.id != "new") {
      getPerformanceCycle()
    }
    else {
      PerformanceCycle.value.PerformancePhaseID = 0
      PerformanceCycle.value.Year = new Date().getFullYear()
    }
  }
  else {
    updateResultMessage("error|No Access|You don't have admin role that allows you to access the page")
    router.push({
      name: "performance-review-list",
      params: {
          type: "current",
          result: "You don't have admin role that allows you to access the page",
      }
    })
  }
})
</script>
