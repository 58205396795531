<template><div>
<v-row>
  <v-col>
    <v-card
      class="mt-0 mb-6 ml-2 mr-2 mt-4 d-flex flex-column"
      rounded="xl"
      elevation="4"
      style="overflow-y: auto"
      min-height="620"
    >
      <v-card-text>
        <v-expansion-panels
          rounded="xl">
          <v-expansion-panel
            static
            color="secondary">
            <v-expansion-panel-title>
              <v-row>
                <v-col class="d-flex justify-end">
                  Filters and Options
                </v-col>
              </v-row>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              class="mb-n4">
              <v-row>
                <v-col></v-col>
                <v-col class="d-flex justify-end">
                  <xlsxexportbutton class="mr-2" :rawJSON="filteredItems" :updateMessage="updateResultMessage"></xlsxexportbutton>
                  <v-btn
                    variant="tonal"
                    color="primary"
                    icon=""
                    @click="addNew()"
                  >
                    <v-icon icon="mdi-plus-thick" />
                    <v-tooltip
                      activator="parent"
                      location="top"
                    >Add New
                    </v-tooltip>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="mb-6 pb-10"></div>
                </v-col>
                <v-col justify="end">
                  <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    label="Search"
                    density="compact"
                    single-line
                    hide-details
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-data-table
          density="compact"
          :headers="lookupHeaders"
          :items="filteredItems"
          :item-value="lookupPrimaryKey"
          items-per-page="-1"
          hide-default-footer="true">
          <template v-slot:item.CPIBandID="{ item }">
            {{ item.CPIBandID }}
          </template>
          <template v-slot:item.CPIBandName="{ item }">
            {{ item.CPIBandName }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div>
              <v-btn
                color="primary"
                icon=""
                variant="plain"
                density="compact"
                @click="showEdit(item.CPIBandID)"
              ><v-icon size="sm" icon="mdi-eye" />
                <v-tooltip
                  activator="parent"
                  location="top"
                >View {{  item.Name }}
                </v-tooltip>
              </v-btn>
            </div>
          </template>
          <template v-slot:bottom> </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-col>
</v-row>
<v-row><v-col></v-col></v-row>

<v-dialog v-model="showDialogEditItem" width="750px">
  <v-form v-model="isEditValid">
    <v-card>
      <v-card-title>Edit {{ lookupNameReadable }}</v-card-title>
      <v-card-text>
        <v-row><v-col></v-col></v-row>
        <v-row>
          <v-col class="pa-0 ma-1" cols="2"><v-text-field
            density="compact"
            persistent-placeholder
            variant="plain"
            readonly
            type="number"
            label="ID"
            v-model="editItem.CPIBandID"
          /></v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0 ma-1"><v-text-field
            density="compact"
            persistent-placeholder
            variant="underlined"
            label="CPI Band Name"
            :rules="rulesIsRequired"
            v-model="editItem.CPIBandName"
          /></v-col>
          <v-col class="pa-0 ma-1" cols="2"><v-text-field
            density="compact"
            persistent-placeholder
            variant="underlined"
            type="number"
            min="-1"
            label="CPI Addition"
            hint="Set to -1 for no increase"
            :rules="rulesIsRequired"
            v-model="editItem.CPIAddition"
          /></v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="text-caption text-grey">Score</div>
            <v-range-slider
              thumb-label
              color="secondary"
              strict
              :min="0"
              :max="100"
              :step="1"
              hide-details
              v-model="editItemScoreRange"
            >
              <template v-slot:prepend>
                <v-text-field
                  hide-details
                  single-line
                  type="number"
                  variant="outlined"
                  density="compact"
                  style="width: 80px"
                  :min="0"
                  :max="editItem.MaximumScore"
                  v-model="editItem.MinimumScore"
                  @update:model-value="editItemScoreRange[0] = $event"
                ></v-text-field>
              </template>
              <template v-slot:append>
                <v-text-field
                  v-model="editItem.MaximumScore"
                  hide-details
                  single-line
                  type="number"
                  variant="outlined"
                  style="width: 80px"
                  density="compact"
                  :min="editItem.MinimumScore"
                  :max="100"
                  @update:model-value="editItemScoreRange[1] = $event"
                ></v-text-field>
              </template>
            </v-range-slider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :disabled="!isEditValid"
          @click="saveEditItem(editItem.CPIBandID)"
        >Save</v-btn>
        <v-btn
          color="error"
          @click="cancelEditItem()"
        >Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</v-dialog>

</div></template>
<script setup>
import { ref, watch, onMounted, computed } from 'vue'
import authService from '@/services/authService'
import utils from '@/services/utils'
import { useDate } from 'vuetify'
import { useRouter } from 'vue-router'

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const props = defineProps({
  updateMessage: Function
})
var search = ref(null)
var filteredItems = ref([])
var selectedIsActive = ref(true)

const lookupPrimaryKey = "CPIBandID"
const lookupName = "CPIBands"
const lookupNameReadable = "CPI Bands"
const lookupHeaders = [
  { title: "ID", key: "CPIBandID", value: "CPIBandID", width: "90px"},
  { title: "Name", key: "CPIBandName", value: "CPIBandName" },
  { title: "Min Score ( >= )", key: "MinimumScore", value: "MinimumScore" },
  { title: "Max Score ( < )", key: "MaximumScore", value: "MaximumScore" },
  { title: "CPI Addition", key: "CPIAddition", value: "CPIAddition" },
  { title: "Actions", key: "actions", width: "90px", sortable: false },
]

var editItem = ref({})
var editItemScoreRange = ref([0, 100])
var isEditValid = ref(false)

var showDialogEditItem = ref(false)

var lookupItems = ref([])
const isLoading = ref(false)

const rulesIsRequired = [v => !!v || "Value is required"]

watch (editItem, (newValue) => {
  editItemScoreRange.value = [newValue.MinimumScore, newValue.MaximumScore]
})

watch (editItemScoreRange, (newValue) => {
  editItem.value.MinimumScore = newValue[0]
  editItem.value.MaximumScore = newValue[1]
})

function updateResultMessage(message) {
  props.updateMessage(message)
}

async function getLookups() {
}

watch(selectedIsActive, (newValue, oldValue) => {
  resetFilter()
})

watch(search, (newValue, oldValue) => {
  resetFilter()
})

function resetFilter() {
  isLoading.value = true
  filteredItems.value = lookupItems.value.filter(item => {
    let textSearch = utils.jsonObjectHasText(item, search.value)
    return textSearch
  })
  isLoading.value = false
}

async function getData() {
  await utils.httpRequest(`/Static/${lookupName}/`, utils.REQUEST.GET, (data) => {
    lookupItems.value = data
  })
  resetFilter()
}

async function getOneData(dataID) {
  await utils.httpRequest(`/Static/${lookupName}/${dataID}`, utils.REQUEST.GET, (data) => {
    editItem.value = data
  })
}

async function showEdit(dataID) {
  await getOneData(dataID)
  showDialogEditItem.value = true
}

function addNew() {
  editItem.value = {}
  editItem.value.CPIBandID = null
  showDialogEditItem.value = true
}

async function saveEditItem(dataID) {
  let payload = editItem.value
  let uri = `/Static/${lookupName}`
  if (dataID) {
    uri = uri + `/${dataID}`
  }
  try {
    await utils.httpRequest(uri, utils.REQUEST.POST, (data) => {
      if (!data.Error) {
        updateResultMessage("success|Saved|Saved Successfully", )
      } else {
        throw new Error(data.Error.Message)
      }
    },
    payload)
    await getData()
    showDialogEditItem.value = false
  } catch (e) {
      updateResultMessage("error|Save error|" + e.message)
  }
}

function cancelEditItem() {
  showDialogEditItem.value = false
}

onMounted(async () => {
  await getData()
  await getLookups()
})

</script>
