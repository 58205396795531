<template>
  <div class="ml-3">
    <v-row class="mt-1">
      <v-col cols="3">
        <v-card
          class="d-flex flex-column"
          rounded="xl"
          color="primary"
          elevation="4">
          <v-card-title
            class="ml-4 mt-4">
            <v-row>
              <v-col class="text-h6">Lookups</v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-divider color="white" class="mb-4"></v-divider>
            <v-tabs
              class="ml-n2 mr-n2 mt-auto"
              density="comfortable"
              direction="vertical"
              v-model="selectedLookupTab">
              <v-tab
                v-for="tab in tabs"
                :value="tab.name"
                :to="tab.name"
                variant="text"
                slim
              >
                <v-icon left size="large">{{ tab.icon }}</v-icon>
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="9">
        <v-container
          fluid
          class="mt-n4"
        >
          <v-row>
            <v-col>
              <v-card
                class="mt-0 ml-2 mr-2 d-flex flex-column"
                rounded="xl"
                style="overflow-y: auto"
                elevation="4">
                <v-card-title class="bg-secondary">
                  <v-col>
                    <v-icon :icon="getTabIcon(selectedLookupTab)" size="large"></v-icon>
                    {{ getTabTitle(selectedLookupTab) }}
                  </v-col>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-window
                direction="vertical"
                v-model="selectedLookupTab">
                  <v-window-item
                    v-for="tab in tabs"
                    :value="tab.name">
                      <component :is="tab.component" :updateMessage="updateResultMessage"></component>
                  </v-window-item>
              </v-window>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import authService from '@/services/authService'
import utils from '@/services/utils'

const route = useRoute()
const router = useRouter()

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])

var selectedLookupTab = ref("")

const tabs = ref([
  { name: "PerformanceMetrics", component: "performancemetrics", title: "Performance Metrics", icon: "mdi-chart-bar" },
  { name: "PerformanceValueFactors", component: "performancevaluefactors", title: "Performance Value Factors", icon: "mdi-domain" },
  { name: "CPIBands", component: "cpibands", title: "CPI Bands", icon: "mdi-tune" },
])

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

function getTabTitle(tabName) {
  const tab = tabs.value.find((t) => t.name === tabName);
  return tab ? tab.title : null;
}

function getTabIcon(tabName) {
  const tab = tabs.value.find((t) => t.name === tabName);
  return tab ? tab.icon : null;
}

onMounted(async () => {
  let isAuthorized = await authService.isPerformanceAdmin() || await authService.isCompensationAdmin()

  if (!isAuthorized) {
    updateResultMessage("error|Not Authorized|You are not authorized to view Lookups")
    router.push({
      name: "not-authenticated"
    })
  }

  selectedLookupTab = ref(route.params.id || selectedLookupTab)
  if (!route.params.id) {
    router.push(`/lookups/${selectedLookupTab.value}`)
  }
  emit("updateBreadcrumb", ["Lookups", route.params.id])
  emit("updateHeader", "Lookups - " + route.params.id)
})

</script>